import * as React from "react";
import {
    MasterPagesDefined,
    UserAvatar as UserAvatarCommon,
    HeaderComponents,
    MasterPageStyleClass,
    MasterPageProps,
    RoleName,
    Logo
} from "gl-commonui";
import { warppedUserAvatar } from "@app/components/user-avatar";
import { LocalizerLocale } from "../locales/localeid";
import { PathConfig } from "../config/pathconfig";



const UserAvatar = warppedUserAvatar(UserAvatarCommon);

const allowedRoles = [
    RoleName.systemAdmin,
    RoleName.translationManager
];

const masterPagesDefined: MasterPagesDefined = {
    appName: LocalizerLocale.projectName,
    back: {
        props: {
            headerComponents: {
                left: [
                    <div className="gl-header-logo">
                        <Logo />
                        <div className="divider"></div>
                        <div style={{ fontSize: 20 }}>
                            Localizer
                        </div>
                    </div>
                ],
                right: [
                    <UserAvatar
                        allowedRoles={allowedRoles}
                        pageAfterSignin={PathConfig.Dashboard}
                        pageAfterSignout={PathConfig.Dashboard}
                    />
                ]
            } as HeaderComponents,
            styleClass: {
                entirety: {
                    class: "portal-theme"
                }
            } as MasterPageStyleClass
        } as MasterPageProps
    }
};

export { masterPagesDefined };
