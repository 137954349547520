import { isFunction } from "lodash";
import { useEffect } from "react";
import { toggleClass } from "wijmo/wijmo";
import { CellRange, CellRangeEventArgs, CellType, FlexGrid, _NewRowTemplate } from "wijmo/wijmo.grid";
import { FormatItemEventArgs } from "wijmo/wijmo.grid";

export function useWijmoEditingHandler<T>(
    grid: FlexGrid,
    beginningEditCallback?: WijmoCellEditCallback,
    cellEditEndingCallback?: WijmoCellEditCallback,
    cellEditEndedCallback?: WijmoCellEditCallback,
    cellDisableHandler?: WijmoCellEditCallback,
) {
    useEffect(() => {
        if (grid && !grid.isReadOnly) {
            grid.beginningEdit.addHandler(beginningEdit);
            grid.cellEditEnding.addHandler(cellEditEnding);
            grid.cellEditEnded.addHandler(cellEditEnded);
            grid.pastingCell.addHandler(pastingCell);
            grid.pastedCell.addHandler(pastedCell);

            if (isFunction(cellDisableHandler)) {
                grid.formatItem.addHandler(formatItem);
            }
        }
        return () => {
            if (grid && !grid.isReadOnly) {
                grid.beginningEdit.removeHandler(beginningEdit);
                grid.cellEditEnding.removeHandler(cellEditEnding);
                grid.cellEditEnded.removeHandler(cellEditEnded);
                grid.pastingCell.removeHandler(pastingCell);
                grid.pastedCell.removeHandler(pastedCell);

                if (isFunction(cellDisableHandler)) {
                    grid.formatItem.removeHandler(formatItem);
                }
            }
        };
    }, [grid]);

    const beginningEdit = (grid: FlexGrid, event: CellRangeEventArgs): void => {
        if (isFunction(beginningEditCallback)) {
            const dataItem = grid.collectionView.items[event.row];
            beginningEditCallback(grid, event, dataItem);
        }
    };
    const cellEditEnding = (grid: FlexGrid, event: CellRangeEventArgs): void => {
        if (isFunction(cellEditEndingCallback)) {
            const dataItem = grid.collectionView.items[event.row];
            cellEditEndingCallback(grid, event, dataItem);
        }
    };

    const cellEditEnded = (grid: FlexGrid, event: CellRangeEventArgs): void => {
        if (isFunction(cellEditEndedCallback)) {
            const dataItem = grid.collectionView.items[event.row];
            cellEditEndedCallback(grid, event, dataItem);
        }
    };

    const pastingCell = (grid: FlexGrid, event: CellRangeEventArgs): void => {
        if (isFunction(beginningEditCallback)) {
            const dataItem = grid.collectionView.items[event.row];
            beginningEditCallback(grid, event, dataItem);
        }
    };

    const pastedCell = (grid: FlexGrid, event: CellRangeEventArgs): void => {
        if (isFunction(cellEditEndedCallback)) {
            const dataItem = grid.collectionView.items[event.row];
            cellEditEndedCallback(grid, event, dataItem);
        }
    };

    const formatItem = (grid: FlexGrid, event: FormatItemEventArgs): void => {
        const row = grid.rows[event.row];
        if (event.panel.cellType === CellType.Cell && !(row instanceof _NewRowTemplate)) {
            const dataItem = grid.rows[event.row].dataItem;
            const column = grid.columns[event.col];
            let isDisabled = column.isReadOnly;
            if (!isDisabled) {
                const cellEvent = new CellRangeEventArgs(event.panel, new CellRange(event.row, event.col));
                cellDisableHandler(grid, cellEvent, dataItem);
                isDisabled = cellEvent.cancel;
            }
            toggleClass(event.cell, "wj-state-disabled", isDisabled);
        }
    };

    return null;
}

export interface WijmoCellEditCallback<T = any> {
    (flex: FlexGrid, event: CellRangeEventArgs, dataItem: T): void;
}
