import { GLServiceBase } from "gl-commonui";
import { injectable } from "@app/util/di";
import { IDocumentsService } from "./interface";
import { GetApplicationRequest, ApplicationRequest } from "./model";

@injectable()
export class DocumentsService
    extends GLServiceBase<ApplicationRequest, GetApplicationRequest>
    implements IDocumentsService
{
    serviceRoute = { prefix: "localizer/v1/documents", itemSuffix: "{id}" };

    getDocument(sectionId?: number) {
        return this.get(`list/${sectionId}`);
    }

    getDocumentByID(data) {
        return this.get(`getbyid`, data);
    }

    createDocument(data) {
        return this.request.post(this.url(""), data);
    }

    updateDocument(data) {
        return this.request.put(this.url(""), data);
    }

    deleteDocumentByID(id) {
        return this.delete(`${id}`);
    }
}
