import {
    GLServiceBase,
    HttpMethod,
    ServerExceptionCode,
    getServiceMethodKey,
    ServiceRoute,
} from "gl-commonui";
import { injectable } from "@app/util/di";
import { IPageService } from "./interface";
import { PageModel } from "./model";
import { LocalizerServiceExceptionCode } from "@app/util";
import { LocalizerLocale } from "@app/locales/localeid";

@injectable()
export class PageService
    extends GLServiceBase<any, any>
    implements IPageService
{
    serviceRoute = { prefix: "localizer/v1/pages", itemSuffix: "{id}" };

    errMsgs = (() => {
        const result = {};

        result[
            getServiceMethodKey(
                this,
                HttpMethod.Post,
                LocalizerServiceExceptionCode.DuplicateUrlException,
                `${this.serviceRoute.prefix}/${"addupdate"}`
            )
        ] = LocalizerLocale.ManagePageFormUrlNotUniqueMessge;

        result[
            getServiceMethodKey(
                this,
                HttpMethod.Post,
                ServerExceptionCode.TargetHasExistedException,
                `${this.serviceRoute.prefix}/${"addupdate"}`
            )
        ] = LocalizerLocale.ManagePageFormNameNotUniqueMessge;

        result[
            getServiceMethodKey(
                this,
                HttpMethod.Delete,
                LocalizerServiceExceptionCode.EntityDoesNotExistException
            )
        ] = LocalizerLocale.CommonEntityNotExist;
        return result;
    })();

    getPagesByApplicationId(id: number): Promise<PageModel[]> {
        return this.get("{id}", { id });
    }
    addUpdatePage(pageInfo: PageModel) {
        return this.createWithoutLoader("addupdate", pageInfo);
    }
    deletePage(id: number) {
        return this.delete("{id}", { id });
    }
}
