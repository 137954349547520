import { StateType as BaseStateType, States } from "gl-commonui";
import tag, { TagState } from "./tag";

export interface StateType extends BaseStateType {
    tag: TagState;
}

export default [tag];

States.clear({
    tag: States.default,
});
