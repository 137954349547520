export class PathConfig {
    static Dashboard = "/";
    static File = "/file";
    static Text = "/text";
    static Page = "/page";
    static NotFound = "/notfound";
    static AnotherPage = "/test";
    static TranslationManager = "/translation-manager";
    static ManagePermissions = "/managepermissions/:userId?";
    static LocalizerAccessDenied = "/warning/localizer/accessdenied";
    static ManagePermissionPage = "/managepermissions/";
    static ManageAreaSection = "/manage-area-section";
    static ManageAreaSectionFile = "/manage-area-section/file";
    static ManageAreaSectionFileAddEdit =
        "/manage-area-section/file/addedit/:id?";
    static ManageAreaSectionTextAddEdit =
        "/manage-area-section/text/addedit/:id?";
    static ManageAreaSectionFileAddEditNew =
        "/manage-area-section/file/addedit";
    static ManageAreaSectionTextAddEditNew =
        "/manage-area-section/text/addedit";
    static ManageAreaSectionPageAddEditNew =
        "/manage-area-section/page/addedit";
    static ManageAreaSectionPageAddEdit =
        "/manage-area-section/page/addedit/:id?";
    static ManageAreaSectionText = "/manage-area-section/text";
    static ManageAreaSectionPage = "/manage-area-section/page";
    static ManageAreaSectionManagePage = "/manage-area-section/page/manage";
}
