import React, { FC } from "react";
import { ColumnLink, WijmoGrid } from "@app/components/grid";
import { Col, Layout, Row, Typography } from "antd";
import { GLGlobal, GLUtil } from "gl-commonui";
import { useEffect, useState } from "react";
import { fmtMsg, PermissionAction, TYPES } from "@app/util";
import {
    ApplicationModel,
    IApplicationService,
} from "@app/service/application/index";
import { useService } from "@app/service/useService";
import { ApplicationService } from "@app/service/application";
import { LocalizerLocale } from "@app/locales/localeid";
import { FormattedMessage } from "react-intl";
import { Action } from "@app/components/actions/action";
import { PathConfig } from "@app/config/pathconfig";
import { useNavigate } from "react-router-dom";

const { Grid, Column } = WijmoGrid;
const { Text, Title, Paragraph, Link } = Typography;

const FileListing: FC<{}> = () => {
    const applicationService = useService<IApplicationService>(
        TYPES.IApplicationService
    );
    const [files, setFiles] = useState<ApplicationModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const fetchGridData = () => {
        setLoading(true);
        applicationService
            .getApplicationsFile()
            .then((data) => {
                setFiles(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchGridData();
    }, []);

    const add = () => {
        navigate(GLGlobal.isLocalizerActionValid(PermissionAction.AddEdit_FileAreaAndSection) ? 
        PathConfig.ManageAreaSectionFileAddEditNew
        : PathConfig.LocalizerAccessDenied)
    };
    const manageGroups = () => {};

    return (
        <Layout>
            <Row>
                <Col span={20}>
                    <Title level={2}>
                        <FormattedMessage
                            id={LocalizerLocale.ManageAreaSection}
                        />
                    </Title>
                </Col>
            </Row>
            <Row gutter={[16, 16]} justify="start">
                <Col span={24}>
                    <Grid
                        itemsSource={files}
                        loading={loading}
                        pagination={true}
                        paginationSize={10}
                        actions={[
                            <Action
                                key="0"
                                textLocaleId={LocalizerLocale.AddAreaSections}
                                onClick={add}
                            />,
                            <Action
                                key="1"
                                textLocaleId={LocalizerLocale.ManageGroups}
                                onClick={manageGroups}
                            />,
                        ]}
                    >
                        <Column
                            header={fmtMsg({
                                id: LocalizerLocale.ManageAreaSectionFileListName,
                            })}
                            binding="name"
                            align="left"
                            width="2*"
                            render={(record, item) => {
                                if (!item.id) {
                                    return "";
                                }
                                return (
                                    <ColumnLink
                                        url={GLGlobal.isLocalizerActionValid(PermissionAction.AddEdit_FileAreaAndSection) ? GLUtil.pathStringify(
                                            PathConfig.ManageAreaSectionFileAddEdit,
                                            {
                                                id: item.id,
                                            }
                                        )
                                        :
                                        PathConfig.LocalizerAccessDenied
                                    }
                                    >
                                        {item.name}
                                    </ColumnLink>
                                );
                            }}
                        />
                        <Column
                            header={fmtMsg({
                                id: LocalizerLocale.ManageAreaSectionFileListUngroupedKeys,
                            })}
                            binding="ungroupedKeys"
                            align="left"
                            width="2*"
                        />
                        <Column
                            header={fmtMsg({
                                id: LocalizerLocale.ManageAreaSectionFileListAllowPublish,
                            })}
                            binding="allowPublish"
                            align="left"
                            width="2*"
                        />
                    </Grid>
                </Col>
            </Row>
        </Layout>
    );
};
export default FileListing;
