import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './gl-link.less';

export const GLLink = props => {
    return (
        <NavLink 
            className ='gl-link'
            activeClassName = 'active'
            exact
            {...props} 
        > 
            {props.children} 
        </NavLink>
    )
}