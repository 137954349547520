import * as React from "react";
import { ColumnLink, WijmoGrid } from "@app/components/grid";
import {
    Badge,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Layout,
    message,
    Modal,
    notification,
    Row,
    Select,
    Space,
    Typography,
    Upload,
} from "antd";
import "./create-update-section.less";
import {
    DeleteOutlined,
    ExclamationCircleFilled,
    InboxOutlined,
} from "@ant-design/icons";
import { fmtMsg, ManageAreaPageType, SelectModel, TYPES } from "@app/util";
import { LocalizerLocale } from "@app/locales/localeid";
import { FormattedMessage } from "react-intl";
import { Action } from "@app/components/actions/action";
import Dragger from "antd/es/upload/Dragger";
import type { UploadProps } from "antd";
import { useEffect, useId, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PathConfig } from "@app/config/pathconfig";
import { SectionModel } from "@app/service/application";
import { ApplicationSections } from "@app/service/sections";
import { useService } from "@app/service/useService";

const { Grid, Column } = WijmoGrid;

const { Text, Title, Paragraph } = Typography;

export type sectionType = "files" | "text" | "pages";

interface SectionProps {
    isNew: boolean;
    type: sectionType;
}

const CreateUpdateSection: React.FC<SectionProps> = (props) => {
    const { isNew, type } = props;

    const { confirm } = Modal;
    const { Dragger } = Upload;
    const [section, setSection] = useState<SectionModel[]>([]);
    const [newSection, setNewSection] = useState<SectionModel[]>([]);
    const [importedSection, setImportedSection] = useState<SectionModel[]>([]);
    const [name, setName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [deletedItems, setDeletedItems] = useState<SectionModel[]>([]);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [duplicated, setDuplicated] = useState<boolean>(false);
    const applicationSections = useService<ApplicationSections>(
        TYPES.ILocalizerSections
    );
    let { id } = useParams();
    const openNotificationWithIcon = (type) => {
        api[type]({
            message: fmtMsg({
                id: LocalizerLocale.CreateAreaExist,
            }),
        });
    };
    const duplicatedNotification = (type) => {
        api[type]({
            message: fmtMsg({
                id: LocalizerLocale.CreateAreaSectionExist,
            }),
        });
    };
    const addSectionNotification = (type) => {
        api[type]({
            message: fmtMsg({
                id: LocalizerLocale.SectionIsEmpty,
            }),
        });
    };
    const saveSectionNotification = (type) => {
        api[type]({
            message: fmtMsg({
                id: LocalizerLocale.SectionSaved,
            }),
        });
    };
    const emptySectionNameNotification = (type) => {
        api[type]({
            message: fmtMsg({
                id: LocalizerLocale.SectionNameIsEmpty,
            }),
        });
    };
    const getSections = () => {
        setLoading(true);
        applicationSections
            .getApplicationSections(id)
            .then((data) => {
                setSection(data.sections);
                setName(data.name);
                setImportedSection([]);
                setDeletedItems([]);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        if (!isNew) {
            getSections();
        }
    }, []);

    const onSave = () => {
        const isNameNotEmpty = section.every(
            (obj) => obj.name.trim().length > 0
        );

        if (section.length <= 0 || !isNameNotEmpty) {
            !isNameNotEmpty
                ? emptySectionNameNotification("info")
                : addSectionNotification("info");
        } else {
            const deletedIds = deletedItems.map((obj) => obj.id);
            let mergedSections = [...newSection, ...importedSection];
            let editSectionsData = {
                id: Number(id),
                name: name,
                translationTypeId:
                    type === "text" ? 1 : type === "files" ? 2 : 3,
                sectionsToAdd: mergedSections.map((section) => ({
                    ...section,
                    id: 0,
                })),
                sectionsToEdit: section.filter(
                    (item) => !item.id.toString().startsWith("tmp-")
                ),
                sectionsToDelete: deletedIds,
            };
            if (deletedItems.length > 0 && !isNew) {
                const names = deletedItems.map((obj) => obj.name);
                const namesString = names.join(", ");
                confirm({
                    title: fmtMsg({
                        id: LocalizerLocale.EditAreaDeleteTitle,
                    }),
                    content: fmtMsg(
                        {
                            id: LocalizerLocale.EditAreaSaveConfirmation,
                        },
                        { sectionNames: namesString }
                    ),
                    icon: (
                        <ExclamationCircleFilled
                            className={"info-icon-color"}
                        />
                    ),
                    okButtonProps: {
                        className: "custom-ok-button",
                    },
                    cancelButtonProps: {
                        className: "custom-cancel-button",
                    },
                    onOk() {
                        if(editSectionsData !=null)
                        {
                            editSectionsData.sectionsToDelete.forEach((item) => {
                                if(typeof item !== 'number' && item.includes('tmp'))
                                {
                                  editSectionsData.sectionsToDelete = [];
                                }
                            });
                            editSectionsData.sectionsToAdd.forEach((item) => {
                                const sections = editSectionsData.sectionsToAdd.filter(item => item.name !== '');
                                 editSectionsData.sectionsToAdd = sections;
                                
                            });
                          applicationSections
                            .updateSection(editSectionsData)
                            .then((data) => {
                                setLoading(false);
                                setUploadedFiles([]);
                                getSections();
                                setNewSection([]);
                                setImportedSection([]);
                                setDeletedItems([]);
                                saveSectionNotification("info");
                            })
                            .catch(() => {
                                setLoading(false);
                            });
                        }
                    },
                });
            } else if (!isNew) {
                if(editSectionsData !=null)
                {
                    editSectionsData.sectionsToDelete.forEach((item) => {
                        if(typeof item !== 'number' && item.includes('tmp'))
                        editSectionsData.sectionsToDelete = [];
                    });
                    editSectionsData.sectionsToAdd.forEach((item) => {
                        const sections = editSectionsData.sectionsToAdd.filter(item => item.name !== '');
                         editSectionsData.sectionsToAdd = sections;
                        
                    });
                 applicationSections
                    .updateSection(editSectionsData)
                    .then((data) => {
                        setLoading(false);
                        setUploadedFiles([]);
                        getSections();
                        setNewSection([]);
                        setImportedSection([]);
                        setDeletedItems([]);
                        saveSectionNotification("info");
                    })
                    .catch(() => {
                        setLoading(false);
                    });
                }
            } else {
                let mergedSections = [...section, ...importedSection];
                let data = {
                    name: name,
                    isVisible: true,
                    IsLocked: true,
                    translationTypeId:
                        type === "text" ? 1 : type === "files" ? 2 : 3,
                    allowPublish: true,
                    sections: mergedSections.map((section) => ({
                        ...section,
                        id: 0,
                    })),
                };
                if(editSectionsData !=null)
                {
                    editSectionsData.sectionsToDelete.forEach((item) => {
                        if(typeof item !== 'number' && item.includes('tmp'))
                        editSectionsData.sectionsToDelete = [];
                    });
                    editSectionsData.sectionsToAdd.forEach((item) => {
                        const sections = editSectionsData.sectionsToAdd.filter(item => item.name !== '');
                         editSectionsData.sectionsToAdd = sections;
                        
                    });
                    applicationSections
                    .createSection(data)
                    .then((data) => {
                        if (data.exists) {
                            setLoading(false);
                            setUploadedFiles([]);
                            setNewSection([]);
                            setImportedSection([]);
                            setDeletedItems([]);
                            openNotificationWithIcon("error");
                            saveSectionNotification("info");
                        } else {
                            setLoading(false);
                            navigate(
                                type === "text"
                                    ? PathConfig.ManageAreaSectionText
                                    : type === "files"
                                    ? PathConfig.ManageAreaSectionFile
                                    : PathConfig.ManageAreaSectionPage
                            );
                        }
                    })
                    .catch(() => {
                        setLoading(false);
                    });
                }
            }
        }
    };
    const handleDelete = (id: number) => {
        const itemToDelete = section.find((item) => item.id === id);
        if (itemToDelete) {
            setSection((prevData) => prevData.filter((item) => item.id !== id));
            setDeletedItems((prevDeletedItems) => [
                ...prevDeletedItems,
                itemToDelete,
            ]);
        }
    };

    const uploadProps = {
        name: "file",
        multiple: true,
        className: "section-upload",
        onRemove: (file) => {
            const filteredFiles = uploadedFiles.filter(
                (uploadedFile) => uploadedFile.uid !== file.uid
            );
            setUploadedFiles(filteredFiles);
            setImportedSection([]);
        },
        beforeUpload: (file) => {
            const isFileExist = uploadedFiles.some(
                (uploadedFile) => uploadedFile.name === file.name
            );

            if (isFileExist) {
                message.error("This file has already been uploaded.");
                return false;
            } else {
                setUploadedFiles([...uploadedFiles, file]);
                const reader = new FileReader();

                reader.onload = (e) => {
                    let data = e.target.result;
                    importSections(data);
                };
                reader.readAsText(file);

                // Prevent upload
                return false;
            }
        },
        onChange(info) {
            const { status, response } = info.file;
            if (status !== "uploading") {
            }
            if (status === "done") {
                const filteredFiles = uploadedFiles.filter(
                    (uploadedFile) => uploadedFile.uid !== info.file.uid
                );
                setUploadedFiles(filteredFiles);

                if (response && response.error) {
                    message.error(`${info.file.name} file upload failed.`);
                } else {
                    message.success(
                        `${info.file.name} file uploaded successfully.`
                    );
                }
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {},
        fileList: uploadedFiles,
    };

    const importSections = (data) => {
        const doubleQuotedString = data.replace(/(\w+)\s*:/g, '"$1":');
        const myObject = JSON.parse(doubleQuotedString);
        const newSection: SectionModel[] = [];

        for (const key in myObject) {
            if (myObject.hasOwnProperty(key)) {
                const obj = {
                    id: 0,
                    name: key,
                    applicationId: isNew ? 0 : Number(id),
                    isVisible: true,
                    displayName: myObject[key],
                };
                newSection.push(obj);
            }
        }
        setImportedSection(newSection);
    };
    const addNewRow = () => {
        const randomNumber = Math.floor(Math.random() * 1000) + 1;
        const newSectionToAdd: SectionModel = {
            id: "tmp-" + randomNumber,
            name: "",
            applicationId: isNew ? 0 : Number(id),
            isVisible: true,
            displayName: "",
        };
        setNewSection([...newSection, newSectionToAdd]);
        setSection([...section, newSectionToAdd]);
    };

    const deleteSection = () => {
        confirm({
            title: fmtMsg({
                id: LocalizerLocale.EditAreaDeleteTitle,
            }),
            content: fmtMsg({
                id: LocalizerLocale.EditAreaDeleteMsg,
            }),
            okButtonProps: {
                className: "custom-ok-button",
            },
            cancelButtonProps: {
                className: "custom-cancel-button",
            },
            icon: <ExclamationCircleFilled className={"info-icon-color"} />,
            onOk() {
                applicationSections
                    .deleteSections(id)
                    .then((data) => {
                        setSection(data);
                        setLoading(false);
                        navigate(
                            type === "text"
                                ? PathConfig.ManageAreaSectionText
                                : type === "files"
                                ? PathConfig.ManageAreaSectionFile
                                : PathConfig.ManageAreaSectionPage
                        );
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            },
        });
    };

    const onCellEdit = (grid, event) => {
        const { row, col } = event;
        const column = grid.columns[col];
        if (column.binding === "name" || column.binding === "displayName") {
            const editedValue = grid.getCellData(row, col);
            const columnName = column.binding;
            for (let i = 0; i < grid.rows.length; i++) {
                if (i === row) continue;
                const rowData = grid.rows[i].dataItem;
                const cellValue = rowData && rowData[columnName];
                if (cellValue === editedValue) {
                    setDuplicated(true);
                    duplicatedNotification("error");
                    return;
                } else {
                    setDuplicated(false);
                }
            }
        }
    };

    return (
        <>
            {contextHolder}
            <Layout className={"container"}>
                <Row>
                    <Col span={20}>
                        <Title level={2}>
                            {isNew ? (
                                <FormattedMessage
                                    id={LocalizerLocale.CreateAreaTitle}
                                />
                            ) : (
                                <FormattedMessage
                                    id={LocalizerLocale.EditAreaTitle}
                                />
                            )}
                        </Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={20} className={"editName"}>
                        <Space
                            direction="horizontal"
                            size="large"
                            align="center"
                        >
                            <Title className={"text-font-weight"} level={5}>
                                {isNew ? (
                                    <FormattedMessage
                                        id={LocalizerLocale.CreateAreaName}
                                    />
                                ) : (
                                    <FormattedMessage
                                        id={LocalizerLocale.EditAreaName}
                                    />
                                )}
                            </Title>
                            <Input
                                style={{ width: "100%" }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                maxLength={50}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="start">
                    <Col span={24}>
                        <Grid
                            loading={loading}
                            title={fmtMsg({
                                id: LocalizerLocale.EditAreaSectionTitle,
                            })}
                            cellEditEnded={(grid, event) =>
                                onCellEdit(grid, event)
                            }
                            pagination={false}
                            allowSorting={false}
                            isReadOnly={false}
                            allowAddNew={false}
                            allowDelete={true}
                            listStateShow={false}
                            itemsSource={section}
                            serviceFormatData={(data) => data.data}
                            actions={[
                                <Action
                                    key="0"
                                    materialIcon="add"
                                    textLocaleId={
                                        LocalizerLocale.EditAreaAddSection
                                    }
                                    onClick={addNewRow}
                                />,
                            ]}
                        >
                            <Column
                                isReadOnly={false}
                                header={"Name"}
                                binding="name"
                                align="left"
                                width="2*"
                            />
                            <Column
                                isReadOnly={false}
                                header={"Display Name"}
                                binding="displayName"
                                align="left"
                                width="2*"
                            />
                            <Column
                                header={"Delete"}
                                binding="id"
                                align="center"
                                width={40}
                                render={(record, item) => {
                                    return (
                                        <DeleteOutlined
                                            onClick={() =>
                                                handleDelete(item.id)
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Col>
                </Row>
                {type !== "pages" && (
                    <>
                        <Row className={"importJson"}>
                            <Col span={24}>
                                <Title className={"text-font-weight"} level={5}>
                                    <FormattedMessage
                                        id={LocalizerLocale.EditAreaImportTitle}
                                    />
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Title className={"text-font-weight"} level={5}>
                                    <FormattedMessage
                                        id={LocalizerLocale.EditAreaImportDesc}
                                    />
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Dragger {...uploadProps}>
                                    <p className="ant-upload-text">
                                        {fmtMsg({
                                            id: LocalizerLocale.EditAreaUploadJson,
                                        })}
                                    </p>
                                </Dragger>
                            </Col>
                        </Row>
                    </>
                )}
                <Row className={"importJsonButtonsAction"}>
                    <Col span={21}>
                        {!isNew && (
                            <Button
                                type="primary"
                                danger
                                onClick={deleteSection}
                            >
                                {fmtMsg({ id: LocalizerLocale.EditAreaDelete })}
                            </Button>
                        )}
                    </Col>
                    <Col span={3} className={"importButtonGroup"}>
                        <Button
                            className={"cancelButton"}
                            onClick={() =>
                                navigate(
                                    type === "text"
                                        ? PathConfig.ManageAreaSectionText
                                        : type === "files"
                                        ? PathConfig.ManageAreaSectionFile
                                        : PathConfig.ManageAreaSectionPage
                                )
                            }
                        >
                            {fmtMsg({ id: LocalizerLocale.EditAreaCancel })}
                        </Button>
                        <Button
                            type="primary"
                            disabled={name === "" || duplicated === true}
                            onClick={onSave}
                        >
                            {fmtMsg({ id: LocalizerLocale.EditAreaSave })}
                        </Button>
                    </Col>
                </Row>
            </Layout>
        </>
    );
};

export default CreateUpdateSection;
