import { GLServiceBase } from "gl-commonui";
import { injectable } from "@app/util/di";
import { IApplicationSections } from "./interface";
import { TagModel } from "@app/service/tags";

@injectable()
export class ApplicationSections
    extends GLServiceBase<any, any>
    implements IApplicationSections
{
    serviceRoute = {
        prefix: "localizer/v1/application",
        itemSuffix: "{id}",
    };

    getApplicationSections(applicationId) {
        return this.get("getapplication", { applicationId });
    }

    deleteSections(applicationId) {
        return this.get("delete", {
            applicationId,
        });
    }

    createSection(data) {
        return this.request.post(this.url("create"), data);
    }

    updateSection(data) {
        return this.request.post(this.url("edit"), data);
    }
}
