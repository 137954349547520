import { DIEx, IGLService } from "gl-commonui";
import { useState } from "react";

export function useService<TService extends IGLService>(type: symbol): TService {
    const [serviceState, setServiceState] = useState({});

    if (serviceState[type]) {
        return serviceState[type];
    }

    const service = DIEx.container.get<TService>(type);
    setServiceState({ ...serviceState, [type]: service });
    return service;
}
