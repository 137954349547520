import * as React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./index.less";
import {
    IPermissionService,
    PermissionUpdateRequestModel,
} from "@app/service/permission/index";
import {
    AccessLevel,
    fmtMsg,
    GiveAccess,
    localizedChildStringsMap,
    localizedParentStringsMap,
    permissionChildNamesMap,
    permissionParentNamesMap,
    PermissionScope,
    TYPES,
} from "@app/util/index";
import { useService } from "@app/service/useService";
import { Button, notification, Select } from "antd";
import { FormattedMessage } from "react-intl";
import { LocalizerLocale } from "../locales/localeid";
import { PathConfig } from "@app/config/pathconfig";
import { NotificationPlacement } from "antd/es/notification/interface";

export const ManagePermissionPage = () => {
    const { userId } = useParams();
    const accountService = useService<IPermissionService>(
        TYPES.IPermissionService
    );
    const [users, setUsers] = useState<any[]>();
    const [permissions, setPermissions] = useState<any>();
    const [initialData, setInitialData] = useState<any>();
    const [selectedUser, setSelectedUser] = useState("");
    const [defaultValue, setDefaultValue] = useState(userId || "");
    const navigate = useNavigate();

    const handleAccessChange = (parentId, childId, access) => {
        // if "Full" or "None" button clicked
        if (childId === GiveAccess.Full) {
            const newData = permissions.map((parent) => ({
                ...parent,
                children: parent.children.map((child) => ({
                    ...child,
                    accessLevel: access,
                })),
            }));
            setPermissions(newData);
        } else {
            const newData = permissions.map((parent) => {
                if (parent.id === parentId) {
                    const newChildren = parent.children.map((child) => {
                        if (child.id === childId) {
                            return {
                                ...child,
                                accessLevel: access,
                            };
                        }
                        return child;
                    });
                    return {
                        ...parent,
                        children: newChildren,
                    };
                }
                return parent;
            });
            setPermissions(newData);
        }
    };

    const handleChange = (dropdownSelectedUser: string) => {
        accountService
            .getPermission(dropdownSelectedUser, PermissionScope.Localizer)
            .then((data) => {
                let convertData = convertPermissionData(data);
                setPermissions(convertData);
                setSelectedUser(dropdownSelectedUser);
                setInitialData(convertData);
                setDefaultValue(dropdownSelectedUser);
                navigate(
                    `${PathConfig.ManagePermissionPage}${dropdownSelectedUser}`
                );
            });
    };

    useEffect(() => {
        accountService.getTranslationManagers().then((users) => {
            setUsers(users);
            let userToFetch = null;
            if (userId && users.some((s) => s.value === userId)) {
                userToFetch = userId;
            } else if (users.length > 0) {
                userToFetch = users[0].value;
            }

            if (userToFetch) {
                accountService
                    .getPermission(userToFetch, PermissionScope.Localizer)
                    .then((data) => {
                        let convertData = convertPermissionData(data);
                        setDefaultValue(userToFetch);
                        setPermissions(convertData);
                        setInitialData(convertData);
                        setSelectedUser(userToFetch);
                    });
                navigate(`${PathConfig.ManagePermissionPage}${userToFetch}`);
            }
        });
    }, []);

    const convertPermissionData = (data) => {
        let parentPermissions = data.filter((p) => p.parentId == null);
        let requiredData = [];

        parentPermissions.forEach((parent) => {
            let childPermissions = data
                .filter((c) => c.parentId === parent.id)
                .map((child) => {
                    return {
                        id: child.id,
                        name: child.name,
                        accessLevel: child.permissionAccessLevelId,
                    };
                });

            requiredData.push({
                id: parent.id,
                name: parent.name,
                children: childPermissions,
            });
        });

        return requiredData;
    };
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
        api[type]({
            message: fmtMsg({
                id: LocalizerLocale.PermissionManagerSuccessMsg,
            }),
        });
    };
    const handleupdate = () => {
        accountService
            .updatePermission(selectedUser, checkedPermissionModel())
            .then((data) => {
                openNotificationWithIcon("success");
            });
        setInitialData(permissions);
    };

    const checkedPermissionModel = () => {
        const checkedPermissionModel: PermissionUpdateRequestModel[] = [];
        for (const permission of permissions) {
            for (const child of permission.children) {
                checkedPermissionModel.push({
                    permissionId: child.id,
                    accessLevel: child.accessLevel,
                });
            }
        }

        return checkedPermissionModel;
    };

    const updateParentName = (parentName) => {
        const permissionId = Object.keys(permissionParentNamesMap).find((id) =>
            parentName.includes(id)
        );
        if (permissionId) {
            return (
                <FormattedMessage
                    id={localizedParentStringsMap[permissionId]}
                />
            );
        }
        return parentName;
    };

    const updateChildName = (childName) => {
        const permissionId = Object.keys(permissionChildNamesMap).find((id) =>
            childName.includes(id)
        );
        if (permissionId) {
            return (
                <FormattedMessage id={localizedChildStringsMap[permissionId]} />
            );
        }
        return childName;
    };

    return (
        <>
            {contextHolder}
            <div className="header">
                <FormattedMessage
                    id={LocalizerLocale.PermissionManagerHeader}
                />
            </div>
            {users && users.length > 0 ? (
                <>
                    <div className="select-wrapper">
                        <label className="select-user">
                            <FormattedMessage
                                id={LocalizerLocale.PermissionManagerSelectUser}
                            />
                        </label>
                        <Select
                            value={defaultValue}
                            className="select-button"
                            onChange={handleChange}
                            options={users}
                        />
                    </div>
                    <div className="container-table">
                        <div className="access-button">
                            <div
                                onClick={() =>
                                    handleAccessChange(
                                        null,
                                        GiveAccess.Full,
                                        AccessLevel.None
                                    )
                                }
                            >
                                <FormattedMessage
                                    id={LocalizerLocale.PermissionManagerNone}
                                />
                            </div>
                            <div
                                onClick={() =>
                                    handleAccessChange(
                                        null,
                                        GiveAccess.Full,
                                        AccessLevel.Full
                                    )
                                }
                            >
                                <FormattedMessage
                                    id={LocalizerLocale.PermissionManagerFull}
                                />
                            </div>
                        </div>
                        {permissions &&
                            permissions.map((parent) => (
                                <div key={parent.id} className="card-wrapper">
                                    <div className="card-parent-name">
                                        {updateParentName(parent.name)}
                                    </div>
                                    {parent.children.map((child) => (
                                        <div
                                            className="childrens"
                                            key={child.id}
                                        >
                                            <div>
                                                {updateChildName(child.name)}
                                            </div>
                                            <div className="radio-buttons">
                                                <input
                                                    type="radio"
                                                    name={`access_${parent.id}_${child.id}`}
                                                    value={AccessLevel.None}
                                                    checked={
                                                        child.accessLevel ===
                                                        AccessLevel.None
                                                    }
                                                    onChange={() =>
                                                        handleAccessChange(
                                                            parent.id,
                                                            child.id,
                                                            AccessLevel.None
                                                        )
                                                    }
                                                />
                                                <input
                                                    type="radio"
                                                    name={`access_${parent.id}_${child.id}`}
                                                    value={AccessLevel.Full}
                                                    checked={
                                                        child.accessLevel ===
                                                        AccessLevel.Full
                                                    }
                                                    onChange={() =>
                                                        handleAccessChange(
                                                            parent.id,
                                                            child.id,
                                                            AccessLevel.Full
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        <div className="button-wrapper">
                            <Button
                                type="primary"
                                className="update-button"
                                disabled={
                                    JSON.stringify(initialData) ===
                                    JSON.stringify(permissions)
                                }
                                onClick={handleupdate}
                            >
                                <FormattedMessage
                                    id={
                                        LocalizerLocale.PermissionManagerSaveButton
                                    }
                                />
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <div className="select-wrapper-noUser">
                    <label className="select-user">
                        <FormattedMessage
                            id={LocalizerLocale.PermissionManagerNoUser}
                        />
                    </label>
                </div>
            )}
        </>
    );
};
