import React, { useEffect, useState } from "react";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PushpinFilled,
    PushpinOutlined,
} from "@ant-design/icons";
import { Button, Input, Menu } from "antd";
import { useService } from "@app/service/useService";
import { IApplicationService } from "@app/service/application";
import { TYPES } from "@app/util";
import "./area-section-menu.less";
import { Typography } from "antd";
import { Loading } from "@app/components/grid/loading/loading";
import { FormattedMessage } from "react-intl";
import { LocalizerLocale } from "@app/locales/localeid";

const { Title } = Typography;

interface ApplicationProps {
    type: number;
    onSelectedSection: (value) => void;
    allApplications: (value) => void;
    allSections: (value) => void;
}

const AreaSectionMenu: React.FC<ApplicationProps> = (props) => {
    const { type, onSelectedSection, allApplications, allSections } = props;
    const [collapsedOne, setCollapsedOne] = useState(false);
    const [collapsedTwo, setCollapsedTwo] = useState(false);
    const [areaId, setAreaId] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [section, setSection] = useState<any>([]);
    const [area, setArea] = useState<any>([]);
    const [filterArea, setFilterArea] = useState([]);
    const [filterSection, setFilterSection] = useState([]);
    const [searchTextArea, setSearchTextArea] = useState("");
    const [searchTextSection, setSearchTextSection] = useState("");

    const applicationService = useService<IApplicationService>(
        TYPES.IApplicationService
    );
    const getApplications = () => {
        setLoading(true);
        applicationService
            .getApplicationsByType(type)
            .then((data) => {
                setArea(data);
                allApplications(data);
                setFilterArea(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    const getSection = (id) => {
        setLoading(true);
        applicationService
            .getSectionsByAppId(id)
            .then((data) => {
                setSection(data);
                allSections(data);
                setFilterSection(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getApplications();
        setAreaId(null);
    }, [type]);
    const toggleCollapsed = () => {
        setCollapsedOne(!collapsedOne);
        setCollapsedTwo(!collapsedTwo);
    };
    const onItemClicked = (item) => {
        setAreaId(item.key);
        getSection(item.key);
    };
    const onSectionClicked = (item) => {
        onSelectedSection(item.key);
    };

    const handleSearchArea = (event) => {
        const searchText = event.target.value;
        setSearchTextArea(searchText);
        const filteredData = area.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilterArea(filteredData);
    };
    const handleSearchSection = (event) => {
        const searchText = event.target.value;
        setSearchTextSection(searchText);
        const filteredData = section.filter((item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilterSection(filteredData);
    };

    return (
        <div className={"menu-container"}>
            <div>
                <Button
                    type="primary"
                    onClick={toggleCollapsed}
                    className={"collapse-button"}
                >
                    {collapsedOne && collapsedTwo ? (
                        <MenuUnfoldOutlined />
                    ) : (
                        <MenuFoldOutlined />
                    )}
                </Button>
            </div>
            <div className={"menu"}>
                <div className={"first-menu"}>
                    <Menu
                        mode="inline"
                        theme="light"
                        inlineCollapsed={collapsedOne}
                    >
                        <div
                            className={
                                collapsedOne
                                    ? "menu-title-pin-collapsed"
                                    : "menu-title-pin"
                            }
                        >
                            <Title
                                className={"menu-title"}
                                level={collapsedOne ? 5 : 4}
                            >
                                <FormattedMessage
                                    id={LocalizerLocale.DashboardMenuAreaTitle}
                                />
                            </Title>
                            <div className={"menu-pin"}>
                                {collapsedOne ? (
                                    <PushpinOutlined
                                        className={"pin"}
                                        onClick={() => setCollapsedOne(false)}
                                    />
                                ) : (
                                    <PushpinFilled
                                        className={"pin"}
                                        onClick={() => setCollapsedOne(true)}
                                    />
                                )}
                            </div>
                        </div>
                        <Input
                            type="text"
                            value={searchTextArea}
                            onChange={handleSearchArea}
                            placeholder="Search"
                        />
                        {area.length > 0 ? (
                            filterArea.map((item) => (
                                <Menu.Item
                                    key={item.id}
                                    onClick={(item) => onItemClicked(item)}
                                >
                                    {item.name}
                                </Menu.Item>
                            ))
                        ) : loading ? (
                            <Loading visible={loading} />
                        ) : (
                            <Title className={"no-area-section"} level={5}>
                                <FormattedMessage
                                    id={LocalizerLocale.DashboardMenuNoArea}
                                />
                            </Title>
                        )}
                    </Menu>
                </div>
                <div className={"first-menu"}>
                    {areaId && (
                        <Menu
                            mode="inline"
                            theme="light"
                            inlineCollapsed={collapsedTwo}
                        >
                            <div
                                className={
                                    collapsedTwo
                                        ? "menu-title-pin-collapsed"
                                        : "menu-title-pin"
                                }
                            >
                                <Title
                                    className={"menu-title"}
                                    level={collapsedTwo ? 5 : 4}
                                >
                                    <FormattedMessage
                                        id={
                                            LocalizerLocale.DashboardMenuSectionTitle
                                        }
                                    />
                                </Title>
                                <div className={"menu-pin"}>
                                    {collapsedTwo ? (
                                        <PushpinOutlined
                                            className={"pin"}
                                            onClick={() =>
                                                setCollapsedTwo(false)
                                            }
                                        />
                                    ) : (
                                        <PushpinFilled
                                            className={"pin"}
                                            onClick={() =>
                                                setCollapsedTwo(true)
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <Input
                                type="text"
                                value={searchTextSection}
                                onChange={handleSearchSection}
                                placeholder="Search"
                            />
                            {section.length > 0 ? (
                                filterSection.map((item) => (
                                    <Menu.Item
                                        key={item.id}
                                        onClick={(item) =>
                                            onSectionClicked(item)
                                        }
                                    >
                                        {item.name}
                                    </Menu.Item>
                                ))
                            ) : loading ? (
                                <Loading visible={loading} />
                            ) : (
                                <Title className={"no-area-section"} level={5}>
                                    <FormattedMessage
                                        id={
                                            LocalizerLocale.DashboardMenuNoSection
                                        }
                                    />
                                </Title>
                            )}
                        </Menu>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AreaSectionMenu;
