import { bindingCollection } from "gl-commonui";
import { TYPES } from "./types";
import { ILocalizerService, LocalizerService } from "./admin/index";
import { IAccountService, AccountService } from "./account/index";
import { IPermissionService, PermissionService } from "./permission";
import { ApplicationService, IApplicationService } from "./application";
import { IPageService, PageService } from "./pages";
import { ITagService, TagService } from "./tags";
import {
    ApplicationSections,
    IApplicationSections,
} from "@app/service/sections";
import { DocumentsService, IDocumentsService } from "@app/service/documents";

bindingCollection.push((container) => {
    container
        .bind<ILocalizerService>(TYPES.ILocalizerService)
        .to(LocalizerService);
    container.bind<IAccountService>(TYPES.IAccountService).to(AccountService);
    container
        .bind<IPermissionService>(TYPES.IPermissionService)
        .to(PermissionService);
    container
        .bind<IApplicationService>(TYPES.IApplicationService)
        .to(ApplicationService);
    container.bind<IPageService>(TYPES.IPageService).to(PageService);
    container.bind<ITagService>(TYPES.ITagService).to(TagService);
    container
        .bind<IDocumentsService>(TYPES.IDocumentsService)
        .to(DocumentsService);
    container
        .bind<IApplicationSections>(TYPES.ILocalizerSections)
        .to(ApplicationSections);
});
