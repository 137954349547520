import { GLRequest, GLPagingRequest } from "gl-commonui";

export interface ApplicationModel {
    id: number;
    name: string;
}

export interface DocumentModel {
    id: number;
    title: string;
    documentTags: {
        id: number;
        name: string;
    }[];
    metadata: string;
    applicationId: number;
    sectionId: number;
    permissionLevelId: number;
    sectionName: string[];
    permissionLevelName: string;
    text: string;
    status: {
        id: number;
        name: string;
    };
}

export interface ApplicationRequest extends GLRequest, ApplicationModel {}

export class GetApplicationRequest extends GLPagingRequest {}
