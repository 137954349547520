import React from "react";
import { Navigate, Route } from "react-router-dom";
import { PathConfig } from "./pathconfig";
import { ManagePermissionPage } from "@app/page/manage-permissions";
import Dashboard from "@app/page/dashboard";
import {
    AuthRouteHandler,
    RoleRouteHandler,
    RoleName,
    GLRouteElement,
    GLDefaultRoutes,
    LocalizerPermissionRouteHandler,
    withRouter,
    GLGlobal,
    GLLocale,
    GLNotFoundPage,
    PathConfig as CommonPath,
} from "gl-commonui";
import TranslationManager from "@app/page/translation-manager";
import { ManageAreaSection } from "@app/page/manage-area-section";
import { PermissionAction } from "@app/util";
import { LocalizerLocale } from "@app/locales/localeid";
import { MultiPermissionRouteHandlerRouteHandler } from "./route-handler";
import FileAreaSection from "@app/page/manage-files/file-area-section";
import FileListing from "@app/page/manage-files/file-listing";
import TextListing from "@app/page/manage-text/text-listing";
import PageListing from "@app/page/manage-pages/page-listing";
import ManagePages from "@app/page/manage-pages/manage-pages";
import TextAreaSection from "@app/page/manage-text/text-area-section";
import PageAreaSection from "@app/page/manage-pages/page-area-section";

export const AccessDeniedPage = withRouter(function AccessDeniedPage(props) {
    let message = GLGlobal.intl.formatMessage({
        id: GLLocale.AccessDeniedDesc,
    });
    if (props.router.location.pathname === PathConfig.LocalizerAccessDenied) {
        message = GLGlobal.intl.formatMessage({
            id: LocalizerLocale.LocalizerAccessDeniedMessage,
        });
    }
    return (
        <div className="gl-access-denied">
            <h1>
                {GLGlobal.intl.formatMessage({ id: GLLocale.AccessDenied })}
            </h1>
            <p>{message}</p>
        </div>
    );
});

const handlers = [
    new AuthRouteHandler(),
    new RoleRouteHandler([RoleName.systemAdmin, RoleName.translationManager]),
];

const LocalizerRouter = [
    <Route
        path={PathConfig.Dashboard}
        key={PathConfig.Dashboard}
        element={<GLRouteElement component={Dashboard} handlers={handlers} />}
    >
        <Route
            path={PathConfig.File}
            key={PathConfig.File}
            element={
                <GLRouteElement
                    component={Dashboard}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.View_FileAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
        <Route
            path={PathConfig.Text}
            key={PathConfig.Text}
            element={
                <GLRouteElement
                    component={Dashboard}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.View_FileAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>

        <Route
            path={PathConfig.Page}
            key={PathConfig.Page}
            element={
                <GLRouteElement
                    component={Dashboard}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.View_FileAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
    </Route>,
    <Route
        path={PathConfig.TranslationManager}
        key={PathConfig.TranslationManager}
        element={
            <GLRouteElement
                component={TranslationManager}
                handlers={handlers.concat([
                    new LocalizerPermissionRouteHandler(
                        PermissionAction.View_TranslationManagers
                    ),
                ])}
            />
        }
    ></Route>,

    <Route
        path={PathConfig.ManagePermissions}
        key={PathConfig.ManagePermissions}
        element={
            <GLRouteElement
                component={ManagePermissionPage}
                handlers={handlers.concat([
                    new LocalizerPermissionRouteHandler(
                        PermissionAction.ManageUserPermission
                    ),
                ])}
            />
        }
    ></Route>,
    <Route
        path={PathConfig.LocalizerAccessDenied}
        key={PathConfig.LocalizerAccessDenied}
        element={
            <GLRouteElement component={AccessDeniedPage} handlers={handlers} />
        }
    ></Route>,
    <Route
        key={PathConfig.ManageAreaSection}
        element={
            <GLRouteElement
                component={ManageAreaSection}
                handlers={handlers.concat([
                    new MultiPermissionRouteHandlerRouteHandler([
                        PermissionAction.View_TextAreaAndSection,
                        PermissionAction.View_FileAreaAndSection,
                        PermissionAction.View_PageAreaAndSection,
                    ]),
                ])}
            />
        }
    >
        <Route
            path={PathConfig.ManageAreaSectionFile}
            key={PathConfig.ManageAreaSectionFile}
            element={
                <GLRouteElement
                    component={FileListing}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.View_FileAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
        <Route
            path={PathConfig.ManageAreaSectionFileAddEdit}
            key={PathConfig.ManageAreaSectionFileAddEdit}
            element={
                <GLRouteElement
                    component={FileAreaSection}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.AddEdit_FileAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
        <Route
            path={PathConfig.ManageAreaSectionText}
            key={PathConfig.ManageAreaSectionText}
            element={
                <GLRouteElement
                    component={TextListing}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.View_TextAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
        <Route
            path={PathConfig.ManageAreaSectionTextAddEdit}
            key={PathConfig.ManageAreaSectionTextAddEdit}
            element={
                <GLRouteElement
                    component={TextAreaSection}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.AddEdit_TextAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
        <Route
            path={PathConfig.ManageAreaSectionPage}
            key={PathConfig.ManageAreaSectionPage}
            element={
                <GLRouteElement
                    component={PageListing}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.View_PageAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
        <Route
            path={PathConfig.ManageAreaSectionPageAddEdit}
            key={PathConfig.ManageAreaSectionPageAddEdit}
            element={
                <GLRouteElement
                    component={PageAreaSection}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.AddEdit_TextAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
        <Route
            path={PathConfig.ManageAreaSectionManagePage}
            key={PathConfig.ManageAreaSectionManagePage}
            element={
                <GLRouteElement
                    component={ManagePages}
                    handlers={handlers.concat([
                        new LocalizerPermissionRouteHandler(
                            PermissionAction.AddEdit_PageAreaAndSection
                        ),
                    ])}
                />
            }
        ></Route>
    </Route>,
    <Route
        key={PathConfig.NotFound}
        path={PathConfig.NotFound}
        element={
            <GLRouteElement component={GLNotFoundPage} handlers={handlers} />
        }
    ></Route>,
    <Route
        key={"default"}
        path="*"
        element={<Navigate to={PathConfig.NotFound} replace />}
    />,
    ...GLDefaultRoutes,
];

export default LocalizerRouter;
