import { GLGlobal, Profile, RoleName } from "gl-commonui";
import { createFromIconfontCN } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

export const mergeClasses = (...classes: string[]) => {
    return classes.filter((c) => !!c).join(" ");
};

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export function fmtMsg(
    messageDescriptor: string | FormattedMessage.MessageDescriptor,
    values?: { [key: string]: string | number | boolean | Date }
) {
    return GLGlobal.intl.formatMessage(
        typeof messageDescriptor === "string"
            ? { id: messageDescriptor }
            : messageDescriptor,
        values
    );
}

export function isStringHasUpperCaseLetters(value: string) {
    if (!value) {
        return false;
    }
    return /[A-Z]/.test(value);
}

export function isUrlValid(value: string) {
    if (!value) {
        return null;
    }

    var regex = new RegExp("^http(s)?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$");

    return value.match(regex)
}

export const IconFont = createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_1920998_qwtyc6g5q7f.js",
});
