export enum AccessLevel {
    None = 1,
    Full = 2,
}

export enum PermissionAction {
    Manage_FileAreaAndSection = 1,
    View_FileAreaAndSection = 2,
    AddEdit_FileAreaAndSection = 3,
    Translate_FileAreaAndSection_DeveloperView = 4,
    Translate_FileAreaAndSection_TranslatorView = 5,
    ManageGroups_FileAreaAndSection = 6,

    Manage_TextAreaAndSection = 7,
    View_TextAreaAndSection = 8,
    AddEdit_TextAreaAndSection = 9,
    Translate_TextAreaAndSection_DeveloperView = 10,
    Translate_TextAreaAndSection_TranslatorView = 11,
    ManageGroups_TextAreaAndSection = 12,
    PublishToTest_TextAreaAndSection = 13,
    PublishToLive_TextAreaAndSection = 14,

    Manage_PageAreaAndSection = 15,
    View_PageAreaAndSection = 16,
    AddEdit_PageAreaAndSection = 17,
    Translate_PageAreaAndSection_DeveloperView = 18,
    Translate_PageAreaAndSection_TranslatorView = 19,
    PublishToTest_PageAreaAndSection = 20,
    PublishToLive_PageAreaAndSection = 21,

    Admin = 22,
    View_TranslationManagers = 23,
    AddEdit_TranslationManagers = 24,
    ManageUserPermission = 25,
}

export enum PermissionScope {
    Localizer = 1,
}

export enum ListStates {
    Active,
    Inactive,
    All,
    Pending,
    Future,
}

export enum ManageAreaPageType {
    Text = "text",
    File = "file",
    Page = "page",
}

export enum TranslationType {
    Text = 1,
    File = 2,
    Page = 3,
}

export enum LocalizerServiceExceptionCode {
    EntityDoesNotExistException = 6003,
    DuplicateUrlException = 6007,
}
