import * as React from "react";
import { useEffect, useRef, useState } from "react";
import "./index.less";
import {
    fmtMsg,
    ManageAreaPageType,
    PermissionAction,
    SelectModel,
    TranslationType,
    TYPES,
} from "@app/util";
import { PathConfig } from "@app/config/pathconfig";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "@app/components/container/container";
import { GLGlobal, GLUtil } from "@gl-commonui";
import {
    Button,
    Col,
    Divider,
    Drawer,
    Input,
    Row,
    Select,
    Spin,
    Typography,
} from "antd";
import {
    FileOutlined,
    FontSizeOutlined,
    GlobalOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { LocalizerLocale } from "@app/locales/localeid";
import AreaSectionMenu from "@app/components/dashboard/area-section-menu";
import { Action } from "@app/components/actions/action";
import { ColumnLink, WijmoGrid } from "@app/components/grid";
import { useService } from "@app/service/useService";
import { DocumentModel, IDocumentsService } from "@app/service/documents";
import { EmailEditor } from "react-email-editor";
import { connect } from "react-redux";
import { StateType } from "@app/state";
import { getTags } from "@app/state/tag";
import { TagModel } from "@app/service/tags";
import metadata = Reflect.metadata;

const { Grid, Column } = WijmoGrid;
const { Title, Paragraph } = Typography;
const { TextArea } = Input;

interface ManagePagesProps {
    tags: TagModel[];
    tagLoading: boolean;
    getTags: () => void;
}

const permissions = [
    { id: 1, label: "Global" },
    { id: 2, label: "Internal" },
    { id: 3, label: "Customer" },
    { id: 4, label: "EndUser" },
];
const status = [
    { id: 1, label: "Active" },
    { id: 2, label: "Draft" },
    { id: 3, label: "Pending" },
];
const Dashboard: React.FC<any> = (props: ManagePagesProps) => {
    const [current, setCurrent] = useState(ManageAreaPageType.File);
    const fileSectionPaths = [PathConfig.File];
    const pageSectionPaths = [PathConfig.Page];
    const textSectionPaths = [PathConfig.Text];
    const location = useLocation();
    const navigate = useNavigate();
    const [sectionId, setSectionId] = useState(null);
    const [documentId, setDocumentId] = useState(null);
    const [section, setSection] = useState<any>();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [saveLoader, setSaveLoader] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const emailEditorRef = useRef(null);
    const [document, setDocument] = useState<DocumentModel>();
    const [allApplications, setAllApplications] = useState<any>([]);
    const [allSections, setAllSections] = useState<any>([]);
    const [applicationDefaultValue, setApplicationDefaultValue] =
        useState<any>();
    const [sectionDefaultValue, setSectionDefaultValue] = useState<any>();
    const [statusDefaultValue, setStatusDefaultValue] = useState<any>();
    const [titleDefaultValue, setTitleDefaultValue] = useState<string>("");
    const [permissionsDefaultValue, setPermissionsDefaultValue] =
        useState<any>();
    const [metaDataValue, setMetaDataValue] = useState<any>(null);
    const [tagsDefaultValue, setTagsDefaultValue] = useState<string[]>([]);
    const [tagOptions, setTagOptions] = useState<SelectModel[]>([]);

    const applicationService = useService<IDocumentsService>(
        TYPES.IDocumentsService
    );
    useEffect(() => {
        const tagOptions = props.tags.map((a) => {
            const option: SelectModel = { value: a.name, label: a.name };
            return option;
        });
        setTagOptions(tagOptions);
    }, [props.tags]);
    useEffect(() => {
        const pathname = location.pathname;

        if (textSectionPaths.includes(pathname)) {
            setCurrent(ManageAreaPageType.Text);
        } else if (pageSectionPaths.includes(pathname)) {
            setCurrent(ManageAreaPageType.Page);
        } else if (fileSectionPaths.includes(pathname)) {
            setCurrent(ManageAreaPageType.File);
        }
    }, [location.pathname]);

    const navigateTo = (value) => {
        if (value == ManageAreaPageType.File) {
            navigate(PathConfig.File);
            setCurrent(ManageAreaPageType.File);
        } else if (value == ManageAreaPageType.Page) {
            navigate(PathConfig.Page);
            setCurrent(ManageAreaPageType.Page);
        } else if (value == ManageAreaPageType.Text) {
            navigate(PathConfig.Text);
            setCurrent(ManageAreaPageType.Text);
        }
    };
    const getDocuments = (id) => {
        setLoading(true);
        applicationService
            .getDocument(id)
            .then((data) => {
                setSection(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        props.getTags();
    };
    useEffect(() => {
        if (sectionId) {
            getDocuments(sectionId);
        }
    }, [sectionId]);
    useEffect(() => {
        setSectionId(null);
    }, [current]);
    const getSection = (id) => {
        setSectionId(id);
    };
    const addOption = () => {
        setOpenDrawer(true);
    };
    const getDocumentById = (id) => {
        setSaveLoader(true);
        let data = {
            languageId: 1,
            documentId: id,
            useText: false,
            useMetaData: true,
        };
        applicationService
            .getDocumentByID(data)
            .then((data) => {
                setSaveLoader(false);
                setDocument(data);
                onReady();
            })
            .catch(() => {
                setSaveLoader(false);
            });
    };

    const openDocument = (id) => {
        getDocumentById(id);
        setDocumentId(id);
        setOpenDrawer(true);
    };
    const closeDrawer = () => {
        setOpenDrawer(false);
        setDocument(null);
        setDocumentId(null);
    };

    useEffect(() => {
        const appDefault = document?.applicationId
            ? document.applicationId
            : allApplications.length > 0
            ? allApplications[0].id
            : null;
        setApplicationDefaultValue(appDefault);
        const sectionDefault = document?.sectionId
            ? document.sectionId
            : allSections.length > 0
            ? allSections[0].id
            : null;
        setSectionDefaultValue(sectionDefault);
        const statusValue = document?.status
            ? document.status.id
            : status[0].id;
        setStatusDefaultValue(statusValue);
        const titleValue = document?.title ? document.title : "";
        setTitleDefaultValue(titleValue);

        const tags =
            document?.documentTags && document.documentTags.map((p) => p.name);
        setTagsDefaultValue(tags);

        const permissionsValue = document?.permissionLevelId
            ? document.permissionLevelId
            : permissions[0].id;
        setPermissionsDefaultValue(permissionsValue);
        const metadata = document?.metadata ? document.metadata : null;
        setMetaDataValue(JSON.parse(metadata));
    }, [document, allApplications, allSections]);

    const saveDocument = () => {
        let editorHTML = "";
        let designData = {};

        emailEditorRef.current.editor.exportHtml((data) => {
            const { design, html } = data;
            editorHTML = html;
            designData = design;

            const pageTags = tagsDefaultValue
                ? tagsDefaultValue.map((t) => {
                      const existingTag = props.tags.find(
                          (pt) => pt.name === t
                      );
                      var tagId = 0;
                      if (existingTag) {
                          tagId = existingTag.id;
                      }
                      return { tagId: tagId };
                  })
                : [];

            let requestData = {
                id: documentId ? documentId : 0,
                languageId: documentId ? 1 : null,
                title: titleDefaultValue,
                applicationId: applicationDefaultValue,
                sectionId: sectionDefaultValue,
                permissionLevelId: permissionsDefaultValue,
                documentTranslation: [
                    {
                        statusId: statusDefaultValue,
                        languageId: 1,
                        text: editorHTML,
                        metadata: JSON.stringify(designData),
                    },
                ],
                documentTag: pageTags,
            };

            setLoading(true);
            if (documentId) {
                applicationService
                    .updateDocument(requestData)
                    .then((data) => {
                        setLoading(false);
                        getDocuments(sectionId);
                        setOpenDrawer(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else {
                applicationService
                    .createDocument(requestData)
                    .then((data) => {
                        setLoading(false);
                        getDocuments(sectionId);
                        setOpenDrawer(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }
        });
    };
    const deleteDocument = () => {
        applicationService
            .deleteDocumentByID(documentId)
            .then((data) => {
                getDocuments(sectionId);
                setOpenDrawer(false);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    const handleChange = (value: string[]) => {
        setTagsDefaultValue(value);
    };
    const onReady = () => {
        return emailEditorRef.current.editor.loadDesign(metaDataValue);
    };
    return (
        <Container fullWidth className="reg-page">
            <div className="nav-section">
                {GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_FileAreaAndSection
                ) && (
                    <Button
                        className={"nav-button-custom"}
                        icon={<FileOutlined />}
                        type={
                            current === ManageAreaPageType.File
                                ? "primary"
                                : "default"
                        }
                        onClick={() => navigateTo(ManageAreaPageType.File)}
                    >
                        <FormattedMessage
                            id={LocalizerLocale.ManageAreaSectionFileLink}
                        />
                    </Button>
                )}
                {GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_TextAreaAndSection
                ) && (
                    <Button
                        className={"nav-button-custom"}
                        icon={<FontSizeOutlined />}
                        type={
                            current === ManageAreaPageType.Text
                                ? "primary"
                                : "default"
                        }
                        onClick={() => navigateTo(ManageAreaPageType.Text)}
                    >
                        <FormattedMessage
                            id={LocalizerLocale.ManageAreaSectionTextLink}
                        />
                    </Button>
                )}
                {GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_PageAreaAndSection
                ) && (
                    <Button
                        className={"nav-button-custom"}
                        icon={<GlobalOutlined />}
                        type={
                            current === ManageAreaPageType.Page
                                ? "primary"
                                : "default"
                        }
                        onClick={() => navigateTo(ManageAreaPageType.Page)}
                    >
                        <FormattedMessage
                            id={LocalizerLocale.ManageAreaSectionPageLink}
                        />
                    </Button>
                )}
            </div>
            {current === "page" ? (
                <div className={"dashboard-container"}>
                    <div className={"dashboard-menu"}>
                        <AreaSectionMenu
                            onSelectedSection={getSection}
                            allApplications={setAllApplications}
                            allSections={setAllSections}
                            type={
                                current === "page"
                                    ? TranslationType.Page
                                    : current === "text"
                                    ? TranslationType.Text
                                    : TranslationType.File
                            }
                        />
                    </div>
                    <div className={"dashboard-table"}>
                        {sectionId && (
                            <Grid
                                loading={loading}
                                pagination={false}
                                allowSorting={false}
                                allowAddNew={false}
                                itemsSource={section}
                                search={true}
                                serviceFormatData={(data) => data.data}
                                actions={[
                                    <Action
                                        key="0"
                                        materialIcon="add"
                                        textLocaleId={
                                            LocalizerLocale.DashboardAddOption
                                        }
                                        onClick={addOption}
                                    />,
                                ]}
                            >
                                <Column
                                    header={fmtMsg({
                                        id: LocalizerLocale.DashboardTableTitle,
                                    })}
                                    binding="title"
                                    align="left"
                                    width="2*"
                                    render={(record, item) => {
                                        if (!item.id) {
                                            return "";
                                        }

                                        return (
                                            <Button
                                                type="link"
                                                onClick={() =>
                                                    openDocument(item.id)
                                                }
                                            >
                                                {item.title}
                                            </Button>
                                        );
                                    }}
                                />
                                <Column
                                    header={fmtMsg({
                                        id: LocalizerLocale.DashboardTableTag,
                                    })}
                                    binding="tags"
                                    align="left"
                                    width="2*"
                                    render={(record, item) => {
                                        const tagNames: [] =
                                            item.documentTags.map((i) => {
                                                return i.name;
                                            });
                                        return <div>{tagNames.join(",")}</div>;
                                    }}
                                />
                                <Column
                                    header={fmtMsg({
                                        id: LocalizerLocale.DashboardTablePermission,
                                    })}
                                    binding="permissionLevelName"
                                    align="left"
                                    width="3*"
                                />
                            </Grid>
                        )}
                    </div>
                </div>
            ) : (
                <div>To be developed</div>
            )}
            <Drawer
                title={
                    documentId
                        ? fmtMsg({
                              id: LocalizerLocale.DashboardDocumentCreate,
                          })
                        : fmtMsg({
                              id: LocalizerLocale.DashboardDocumentUpdate,
                          })
                }
                placement="right"
                open={openDrawer}
                width={"100%"}
                maskClosable={true}
                closable={true}
                onClose={closeDrawer}
                destroyOnClose={true}
                className="manage-pages-drawer"
            >
                <Spin spinning={saveLoader}>
                    <Row>
                        <Col span={5}>
                            <Paragraph>
                                <FormattedMessage
                                    id={LocalizerLocale.DashboardSite}
                                />
                            </Paragraph>
                            <Select
                                value={applicationDefaultValue}
                                style={{ width: 120 }}
                                options={
                                    allApplications.length > 0 &&
                                    allApplications.map(({ id, name }) => ({
                                        value: id,
                                        label: name,
                                    }))
                                }
                                onChange={setApplicationDefaultValue}
                            />
                            <Paragraph className={"documents-margin"}>
                                <FormattedMessage
                                    id={LocalizerLocale.DashboardStatus}
                                />
                                Status
                            </Paragraph>
                            <Select
                                value={statusDefaultValue}
                                style={{ width: 120 }}
                                options={
                                    status.length > 0 &&
                                    status.map(({ id, label }) => ({
                                        value: id,
                                        label: label,
                                    }))
                                }
                                onChange={setStatusDefaultValue}
                            />
                            <Paragraph className={"documents-margin"}>
                                <FormattedMessage
                                    id={LocalizerLocale.DashboardSection}
                                />
                            </Paragraph>
                            <Select
                                value={sectionDefaultValue}
                                style={{ width: 120 }}
                                options={
                                    allSections.length > 0 &&
                                    allSections.map(({ id, name }) => ({
                                        value: id,
                                        label: name,
                                    }))
                                }
                                onChange={setSectionDefaultValue}
                            />
                            <Paragraph className={"documents-margin"}>
                                <FormattedMessage
                                    id={LocalizerLocale.DashboardTitle}
                                />
                            </Paragraph>
                            <Input
                                value={titleDefaultValue}
                                onChange={(e) =>
                                    setTitleDefaultValue(e.target.value)
                                }
                            />
                            <Paragraph className={"documents-margin"}>
                                <FormattedMessage
                                    id={LocalizerLocale.DashboardTags}
                                />
                            </Paragraph>
                            <Select
                                value={tagsDefaultValue}
                                mode="multiple"
                                options={tagOptions}
                                onChange={handleChange}
                                maxTagCount={20}
                                style={{ width: "100%" }}
                            />
                            <Paragraph className={"documents-margin"}>
                                <FormattedMessage
                                    id={LocalizerLocale.DashboardPermission}
                                />
                            </Paragraph>
                            <Select
                                value={permissionsDefaultValue}
                                style={{ width: 120 }}
                                options={
                                    permissions.length > 0 &&
                                    permissions.map(({ id, label }) => ({
                                        value: id,
                                        label: label,
                                    }))
                                }
                                onChange={setPermissionsDefaultValue}
                            />
                            <Row className={"documents-actions-margin"}>
                                <Col
                                    span={24}
                                    className={"documents-actions-button"}
                                >
                                    {documentId ? (
                                        <Button
                                            type="primary"
                                            danger
                                            onClick={deleteDocument}
                                        >
                                            {fmtMsg({
                                                id: LocalizerLocale.DashboardDelete,
                                            })}
                                        </Button>
                                    ) : (
                                        <Button
                                            onClick={() => setOpenDrawer(false)}
                                        >
                                            {fmtMsg({
                                                id: LocalizerLocale.DashboardCancel,
                                            })}
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        onClick={saveDocument}
                                    >
                                        {fmtMsg({
                                            id: LocalizerLocale.DashboardSave,
                                        })}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Divider
                            type={"vertical"}
                            className={"divider-custom"}
                        />
                        <Col span={18}>
                            <EmailEditor
                                minHeight={650}
                                style={{ overflowX: "auto" }}
                                ref={emailEditorRef}
                                onReady={onReady}
                            />
                        </Col>
                    </Row>
                </Spin>
            </Drawer>
        </Container>
    );
};
export default connect(
    ({ tag: { tags, loading } }: StateType) => ({
        tagLoading: loading,
        tags,
    }),
    { getTags }
)(Dashboard);
