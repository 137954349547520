export interface PermissionUpdateRequestModel {
    permissionId: number;
    accessLevel: AccessLevel;
}

export enum AccessLevel {
    None = 1,
    Full = 2
}

export interface GetTranslationManagersResponseModel {
    value: string;
    label: string;
}

export interface UserPermissionResponseModel {
    id: number;
    name: string;
    permissionAccessLevelId: number,
    parentId: number;
}