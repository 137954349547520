import { Action } from "@app/components/actions/action";
import { Grid } from "@app/components/grid/grid";
import { Column } from "@app/components/grid/grid-column";
import { LocalizerLocale } from "@app/locales/localeid";
import {
    SelectModel,
    TYPES,
    TranslationType,
    fmtMsg,
    isStringHasUpperCaseLetters,
    isUrlValid,
} from "@app/util";
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Layout,
    Modal,
    Row,
    Select,
    Spin,
    Typography,
} from "antd";
import React, { useEffect, useState } from "react";

const { Text, Title, Paragraph, Link } = Typography;
import { FormattedMessage } from "react-intl";
import "./manage-pages.less";
import { useService } from "@app/service/useService";
import {
    ApplicationInfoModel,
    ApplicationService,
} from "@app/service/application";
import { PageModel, PageService } from "@app/service/pages";
import { connect } from "react-redux";
import { StateType } from "@app/state";
import tag, { getTags } from "@app/state/tag";
import { TagModel } from "@app/service/tags";

interface ManagePagesProps {
    tags: TagModel[];
    tagLoading: boolean;
    getTags: () => void;
}

const ManagePages: React.FC<any> = (props: ManagePagesProps) => {
    const applicationService = useService<ApplicationService>(
        TYPES.IApplicationService
    );
    const pageService = useService<PageService>(TYPES.IPageService);
    const [areas, setAreas] = useState<SelectModel[]>();
    const [selectedArea, setSelectedArea] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const [saveLoader, setSaveLoader] = useState<boolean>(false);
    const [pages, setPages] = useState<PageModel[]>([]);
    const [formTitle, setFormTitle] = useState<string>("");
    const [form] = Form.useForm();
    const [tagOptions, setTagOptions] = useState<SelectModel[]>([]);
    const [selectedPage, SetSelectedPage] = useState<number>(0);
    const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
        useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [addEditVisible, setAddEditVisible] = useState<boolean>(false);

    useEffect(() => {
        async function getAreas() {
            const applicationArea = await getApplicationsArea(
                TranslationType.Page
            );
            const applicationAreaOptions = applicationArea.map((a) => {
                const option: SelectModel = { value: a.id, label: a.name };
                return option;
            });
            setAreas(applicationAreaOptions);
            if (applicationArea.length > 0) {
                const selectedArea = applicationArea[0].id;
                setSelectedArea(selectedArea);
                getPages(selectedArea);
            }
        }

        props.getTags();
        getAreas();
    }, []);

    useEffect(() => {
        const tagOptions = props.tags.map((a) => {
            const option: SelectModel = { value: a.name, label: a.name };
            return option;
        });
        setTagOptions(tagOptions);
    }, [props.tags]);

    const getApplicationsArea = async (typeId: number) => {
        return applicationService.getApplicationsByType(typeId);
    };

    const getPages = (applicationId: number) => {
        setLoading(true);
        pageService
            .getPagesByApplicationId(applicationId)
            .then((data) => {
                setPages(data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const onAdd = () => {
        setFormTitle(fmtMsg({ id: LocalizerLocale.ManagePageFormAddTitle }));
        SetSelectedPage(0);
        onReset();
        setAddEditVisible(true);
    };

    const onEdit = (id: number) => {
        setFormTitle(fmtMsg({ id: LocalizerLocale.ManagePageFormEditTitle }));
        const pageInfo = pages.find((p) => p.id === id);
        if (pageInfo) {
            SetSelectedPage(pageInfo.id);
            var selectedTags = pageInfo.pageTag.map((p) => p.name);
            form.setFieldsValue({
                name: pageInfo.name,
                url: pageInfo.url,
                tags: selectedTags,
            });
            setAddEditVisible(true);
        }
    };

    const handleChange = (value) => {
        setSelectedArea(value);
        getPages(value);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onClose = () => {
        setAddEditVisible(false);
    };

    const onFinish = (values: any) => {
        const pageTags: TagModel[] = values.tags
            ? values.tags.map((t) => {
                  const existingTag = props.tags.find((pt) => pt.name === t);
                  var tagId = 0;
                  if (existingTag) {
                      tagId = existingTag.id;
                  }
                  return { id: tagId, name: t };
              })
            : [];

        const modelToSave: PageModel = {
            id: selectedPage,
            name: values.name,
            url: values.url,
            pageTag: pageTags,
            applicationId: selectedArea,
        };
        setSaveLoader(true);
        pageService
            .addUpdatePage(modelToSave)
            .then((data) => {
                setAddEditVisible(false);
                setSaveLoader(false);
                props.getTags();
                getPages(selectedArea);
            })
            .catch(() => setSaveLoader(false));
    };

    const deletePage = () => {
        setDeleteLoading(true);
        pageService
            .deletePage(selectedPage)
            .then((data) => {
                setDeleteLoading(false);
                setDeleteConfirmationModalOpen(false);
                setAddEditVisible(false);
                getPages(selectedArea);
            })
            .catch(() => {
                setDeleteLoading(false);
            });
    };

    const onDelete = () => {
        setDeleteConfirmationModalOpen(true);
    };

    return (
        <Layout className="manage-pages">
            <Row className="title-section">
                <Col span={20}>
                    <Title level={2}>
                        <FormattedMessage id={LocalizerLocale.ManagePages} />
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col span={20}>
                    <div className="area-section">
                        <label className="">
                            <FormattedMessage
                                id={LocalizerLocale.ManagePageAreaLabel}
                            />
                        </label>
                        <Select
                            value={selectedArea}
                            onChange={handleChange}
                            options={areas}
                            disabled={!areas || areas.length === 0}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Grid
                        itemsSource={pages}
                        loading={loading}
                        pagination={false}
                        actions={[
                            <Action
                                key="0"
                                textLocaleId={LocalizerLocale.AddAreaSections}
                                onClick={areas && areas.length > 0 && onAdd}
                                disabled={!areas || areas.length === 0}
                            />,
                        ]}
                    >
                        <Column
                            header={fmtMsg({
                                id: LocalizerLocale.ManagePageListName,
                            })}
                            binding="name"
                            align="left"
                            width="2*"
                            render={(record, item) => {
                                return (
                                    <a
                                        key={item.id}
                                        onClick={() => onEdit(item.id)}
                                    >
                                        {" "}
                                        {item.name}
                                    </a>
                                );
                            }}
                        />
                        <Column
                            header={fmtMsg({
                                id: LocalizerLocale.ManagePageListUrl,
                            })}
                            binding="url"
                            align="left"
                            width="2*"
                            allowSorting={false}
                        />
                        <Column
                            header={fmtMsg({
                                id: LocalizerLocale.ManagePageListTags,
                            })}
                            binding="pageTag"
                            align="left"
                            width="2*"
                            allowSorting={false}
                            render={(record, item) => {
                                const tagNames: [] = item.pageTag.map((i) => {
                                    return i.name;
                                });
                                return <div>{tagNames.join(",")}</div>;
                            }}
                        />
                    </Grid>
                </Col>
            </Row>

            <Drawer
                title={formTitle}
                placement="right"
                open={addEditVisible}
                width={500}
                maskClosable={false}
                closable={false}
                destroyOnClose={true}
                className="manage-pages-drawer"
            >
                <Spin spinning={saveLoader}>
                    <Form layout="vertical" onFinish={onFinish} form={form}>
                        <div className="form-body">
                            <Form.Item
                                label={fmtMsg({
                                    id: LocalizerLocale.ManagePageFormNameLabel,
                                })}
                                name="name"
                                rules={[
                                    {
                                        max: 50,
                                        message: fmtMsg(
                                            {
                                                id: LocalizerLocale.ManagePageFormNameLengthMessage,
                                            },
                                            { characterLength: 50 }
                                        ),
                                    },
                                    {
                                        required: true,
                                        message: fmtMsg({
                                            id: LocalizerLocale.ManagePageFormNameRequiredMessage,
                                        }),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label={fmtMsg({
                                    id: LocalizerLocale.ManagePageFormUrlLabel,
                                })}
                                name="url"
                                validateFirst={true}
                                rules={[
                                    {
                                        required: true,
                                        message: fmtMsg({
                                            id: LocalizerLocale.ManagePageFormUrlRequiredMessage,
                                        }),
                                    },
                                    {
                                        message: fmtMsg({
                                            id: LocalizerLocale.ManagePageFormUrlInvalidMessage,
                                        }),
                                        validator: (_, value) => {
                                            var res = isUrlValid(value);
                                            if (res === null || !res) {
                                                return Promise.reject();
                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={fmtMsg({
                                    id: LocalizerLocale.ManagePageFormTagLabel,
                                })}
                                name="tags"
                                validateFirst={true}
                                rules={[
                                    {
                                        required: true,
                                        message: fmtMsg({
                                            id: LocalizerLocale.ManagePageFormTagRequiredMessge,
                                        }),
                                    },
                                    {
                                        message: fmtMsg(
                                            {
                                                id: LocalizerLocale.ManagePageFormTagLengthMessge,
                                            },
                                            {
                                                minCharacter: 3,
                                                maxCharacter: 50,
                                            }
                                        ),
                                        validator: (_, value) => {
                                            if (value && value.length > 0) {
                                                const defect = value.some(
                                                    (v) => {
                                                        if (
                                                            v.length < 3 ||
                                                            v.length > 50
                                                        ) {
                                                            return true;
                                                        }
                                                        return false;
                                                    }
                                                );
                                                if (defect) {
                                                    return Promise.reject();
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                    {
                                        message: fmtMsg({
                                            id: LocalizerLocale.ManagePageFormTagMessge,
                                        }),
                                        validator: (_, value) => {
                                            if (value && value.length > 0) {
                                                const all = value.filter(
                                                    (el) => {
                                                        if (
                                                            isStringHasUpperCaseLetters(
                                                                el
                                                            )
                                                        ) {
                                                            return el;
                                                        }
                                                    }
                                                );
                                                if (all.length > 0) {
                                                    return Promise.reject();
                                                }
                                            }

                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    options={tagOptions}
                                    maxTagCount={20}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </div>
                        <div className="form-action">
                            {selectedPage > 0 && (
                                <Button
                                    type="primary"
                                    onClick={onDelete}
                                    className="delete-btn"
                                >
                                    {fmtMsg({
                                        id: LocalizerLocale.ManagePageFormDeleteButton,
                                    })}
                                </Button>
                            )}

                            <div className="form-right-action">
                                <Button onClick={onClose}>
                                    {fmtMsg({
                                        id: LocalizerLocale.ManagePageFormCancelButton,
                                    })}
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    {fmtMsg({
                                        id: LocalizerLocale.ManagePageFormSaveButton,
                                    })}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Spin>
            </Drawer>

            <Modal
                title={fmtMsg({
                    id: LocalizerLocale.ManagePageFormDeleteModalTitle,
                })}
                open={isDeleteConfirmationModalOpen}
                onOk={deletePage}
                onCancel={() => setDeleteConfirmationModalOpen(false)}
                destroyOnClose
                okButtonProps={{ loading: deleteLoading }}
            >
                {fmtMsg({ id: LocalizerLocale.ManagePageFormDeleteMessage })}{" "}
            </Modal>
        </Layout>
    );
};
export default connect(
    ({ tag: { tags, loading } }: StateType) => ({
        tagLoading: loading,
        tags,
    }),
    { getTags }
)(ManagePages);
