import { PermissionScope } from "@app/util";
import { GLServiceBase } from "gl-commonui";
import { injectable } from "../../util/di";
import { IPermissionService } from "./index";
import {
    UserPermissionResponseModel,
    GetTranslationManagersResponseModel,
    PermissionUpdateRequestModel
} from "./model";

@injectable()
export class PermissionService
    extends GLServiceBase<any, any>
    implements IPermissionService
{
    serviceRoute = { prefix: "account/v1/permissions", itemSuffix: "{id}" };
    getTranslationManagers(): Promise<GetTranslationManagersResponseModel> {
        return this.get("translationManagers");
    }
    
    getPermission(userId: string, permissionScope: PermissionScope): Promise<UserPermissionResponseModel> {
        return this.get({ userId: userId, permissionScope: permissionScope });
    }

    updatePermission(userId: string, params: PermissionUpdateRequestModel[]) {
        return this.create("{userId}", params, {
            userId: userId,
        });
    }
}