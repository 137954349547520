import * as React from "react";
import { ColumnLink, WijmoGrid } from "@app/components/grid";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Layout,
    Modal,
    notification,
    Row,
    Select,
    Typography,
} from "antd";
import "./index.less";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { fmtMsg, TYPES } from "@app/util";
import { IAccountService } from "@app/service/account";
import { useService } from "@app/service/useService";
import { LocalizerService } from "@app/service/admin";
import { LocalizerLocale } from "@app/locales/localeid";
import { FormattedMessage } from "react-intl";
import { Action } from "@app/components/actions/action";
import { GLGlobal, GLUtil } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { GridRef } from "@app/components/grid/grid";

const { Grid, Column } = WijmoGrid;

const { Title, Paragraph } = Typography;
const TranslationManager: React.FC<any> = () => {
    const accountService = useService<IAccountService>(TYPES.IAccountService);
    const adminService = useService<LocalizerService>(TYPES.ILocalizerService);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [templateList, seTemplateList] = useState<any>();
    const [email, setEmail] = useState<string>("");
    const [selectedTemplate, setSelectedTemplate] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [userID, setUserId] = useState<string>("");
    const [emailExist, setEmailExist] = useState<boolean>(true);
    const [firstModal, setFirstModal] = useState<boolean>(true);
    const [form] = Form.useForm();
    const { confirm } = Modal;
    const gridRef = useRef<GridRef>(null);
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type) => {
        api[type]({
            message: fmtMsg({
                id: LocalizerLocale.TranslationManagerSuccessMsg,
            }),
        });
    };

    const fetchGridData = () => {
        return accountService.getTranslationManagersList();
    };
    const reloadGrid = () => {
        gridRef.current.reload();
    };
    const deleteUser = (item) => {
        let id = item.id;
        confirm({
            title: fmtMsg({ id: LocalizerLocale.TranslationManagerDeleteUser }),
            icon: <QuestionCircleOutlined />,
            okButtonProps: {
                className: "custom-ok-button",
            },
            cancelButtonProps: {
                className: "custom-cancel-button",
            },
            onOk() {
                if (item.isInvitePending) {
                    adminService.deletePendingUser(id).then(() => {
                        reloadGrid();
                    });
                } else {
                    accountService.deleteUser(id).then(() => {
                        reloadGrid();
                    });
                }
            },
        });
    };
    useEffect(() => {
        let store = GLGlobal.store.getState();
        let id = store.oidc.loginInfo.profile.sub;
        setUserId(id);
    }, []);
    useEffect(() => {
        let templateID =
            templateList && templateList.length > 0 && templateList[0].key;
        setSelectedTemplate(templateID);
    }, [emailExist]);
    const addNewUser = () => {
        let tempList = [];
        adminService.getTemplate().then((template) => {
            template.map((data) => {
                let prepareData = { key: data.id, value: data.name };
                tempList.push(prepareData);
            });
            seTemplateList(tempList);
        });
        setIsModalOpen(true);
    };

    const bindingUser = () => {
        const params = {
            email: email,
            invitationCodeType: 15,
            referenceId: null,
        };
        accountService
            .bindingUserByEmail(params)
            .then((data) => {
                openNotificationWithIcon("success");
                data.loading = false;
                data.triggerListUpdate();
            })
            .catch((error) => {
                error.loading = false;
                console.log(error);
            });
    };
    const inviteUser = () => {
        if (firstModal) {
            accountService.validateEmail({ email: email }).then(({ exist }) => {
                if (exist) {
                    bindingUser();
                    setIsModalOpen(false);
                    setFirstModal(true);
                } else {
                    setEmailExist(false);
                    setFirstModal(false);
                }
            });
        } else {
            let codeVm = {
                email: email,
                invitationType: 15,
                name: name,
                templateId: selectedTemplate,
                senderId: userID,
            };
            adminService.inviteUser(codeVm).then(({ data }) => {
                fetchGridData();
                openNotificationWithIcon("success");
                handleCancel();
                reloadGrid();
            });
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        seTemplateList([]);
        setName(null);
        setEmail("");
        setEmailExist(true);
    };
    const selectTemplate = (value, key) => {
        setSelectedTemplate(key.key);
    };
    const filterFunction = (item: any) => {
        return item.isInvitePending === false;
    };
    return (
        <>
            {contextHolder}
            <Layout className={"container"}>
                <Row>
                    <Col span={20}>
                        <Title level={2}>
                            <FormattedMessage
                                id={LocalizerLocale.TranslationManagerHeader}
                            />
                        </Title>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="start">
                    <Col span={22}>
                        <Grid
                            ref={gridRef}
                            serviceFunction={fetchGridData as any}
                            loading={false}
                            pagination={false}
                            allowSorting={false}
                            allowAddNew={false}
                            listStateShow={true}
                            filterOnload={true}
                            filterFunction={filterFunction}
                            serviceFormatData={(data) => data.data}
                            actions={[
                                <Action
                                    key="0"
                                    materialIcon="add"
                                    textLocaleId={
                                        LocalizerLocale.TranslationManagerInviteUser
                                    }
                                    onClick={addNewUser}
                                />,
                            ]}
                        >
                            <Column
                                header={"Name"}
                                binding="name"
                                align="left"
                                width="2*"
                                render={(record, item) => {
                                    if (!item.id) {
                                        return "";
                                    }

                                    return (
                                        <ColumnLink
                                            url={GLUtil.pathStringify(
                                                PathConfig.ManagePermissions,
                                                {
                                                    userId: item.id,
                                                }
                                            )}
                                        >
                                            {item.name}
                                        </ColumnLink>
                                    );
                                }}
                            />
                            <Column
                                header={"Email"}
                                binding="email"
                                align="left"
                                width="2*"
                            />
                            <Column
                                header={"Actions"}
                                binding="id"
                                align="center"
                                width={40}
                                render={(record, item) => {
                                    return (
                                        <DeleteOutlined
                                            onClick={() => deleteUser(item)}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Col>
                </Row>
                <Modal
                    destroyOnClose={true}
                    title={
                        <FormattedMessage
                            id={LocalizerLocale.TranslationManagerInvite}
                        />
                    }
                    open={isModalOpen}
                    onOk={inviteUser}
                    onCancel={handleCancel}
                    footer={[
                        <Button onClick={handleCancel}>
                            <FormattedMessage
                                id={
                                    LocalizerLocale.TranslationManagerInviteCancel
                                }
                            />
                        </Button>,
                        <Button
                            form="checkUser"
                            key="submit"
                            type="primary"
                            htmlType="submit"
                        >
                            <FormattedMessage
                                id={
                                    LocalizerLocale.TranslationManagerInviteSend
                                }
                            />
                        </Button>,
                    ]}
                >
                    <Divider type="vertical" />
                    <Form
                        onFinish={inviteUser}
                        id={"checkUser"}
                        key="submit"
                        preserve={false}
                        form={form}
                        layout="vertical"
                    >
                        {emailExist ? (
                            <>
                                <Form.Item
                                    name="email"
                                    label={
                                        <FormattedMessage
                                            id={
                                                LocalizerLocale.TranslationManagerInviteEmail
                                            }
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <FormattedMessage
                                                    id={
                                                        LocalizerLocale.TranslationManagerEnterEmail
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            type: "email",
                                            message: (
                                                <FormattedMessage
                                                    id={
                                                        LocalizerLocale.TranslationManagerValidateEmail
                                                    }
                                                />
                                            ),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder=""
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        value={email}
                                    />
                                </Form.Item>
                            </>
                        ) : (
                            <>
                                <Paragraph>
                                    <FormattedMessage
                                        id={
                                            LocalizerLocale.TranslationManagerEmailExist
                                        }
                                        values={{ email: email }}
                                    />
                                </Paragraph>
                                <Paragraph>
                                    <FormattedMessage
                                        id={
                                            LocalizerLocale.TranslationManagerEmailExistInstruction
                                        }
                                    />
                                </Paragraph>

                                <Form.Item
                                    name="name"
                                    label={
                                        <FormattedMessage
                                            id={
                                                LocalizerLocale.TranslationManagerEnterName
                                            }
                                        />
                                    }
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder=""
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        value={name}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ paddingTop: "15px" }}
                                    name="template"
                                    label={
                                        <FormattedMessage
                                            id={
                                                LocalizerLocale.TranslationManagerTemplate
                                            }
                                        />
                                    }
                                >
                                    <Select
                                        defaultValue={
                                            templateList &&
                                            templateList.length > 0 &&
                                            templateList[0].value
                                        }
                                        options={templateList}
                                        style={{ width: "100%" }}
                                        onChange={selectTemplate}
                                    />
                                </Form.Item>
                            </>
                        )}
                    </Form>
                </Modal>
            </Layout>
        </>
    );
};

export default TranslationManager;
