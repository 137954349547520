import * as React from "react";
import { Component } from "react";
import { ListStates, fmtMsg, mergeClasses } from "../util";
import { DashboardLocale } from "../locales/localeid";
import { RadioChangeEvent } from "antd/lib/radio";
import { Radio } from "antd";

const RadioGroup = Radio.Group;

export interface ListStatesRadiosProps {
    value?: any;
    allCnt?: number;
    activeCnt?: number;
    inActiveCnt?: number;
    pendingCount?: number;
    futureCount?: number;
    onChange?: (value: ListStates) => void;
    openGroupMode?: (e) => void;
    showGroupingButton?: boolean;
    showInActiveRadio?: boolean;
    className?: string;
    activeTextLocaleId?: string;
    inActiveTextLocaleId?: string;
    hideCnts?: boolean;
    children?: React.ReactNode;
}

interface ListStatesRadiosStates {}

export class ListStatesRadios extends Component<
    ListStatesRadiosProps,
    ListStatesRadiosStates
> {
    static defaultProps = {
        showInActiveRadio: true,
    };

    constructor(props: ListStatesRadiosProps) {
        super(props);
    }

    onChange(e: RadioChangeEvent) {
        this.props.onChange(parseInt(e.target.value));
    }

    renderState(text: string, cnt: number) {
        return (
            <span>
                <span>{fmtMsg(text)}</span>
                {!this.props.hideCnts && (
                    <span className="cnt">({cnt || 0})</span>
                )}
            </span>
        );
    }

    render() {
        const {
            allCnt,
            activeCnt,
            futureCount,
            inActiveCnt: inActiveCntProp,
            inActiveTextLocaleId: inActiveTextLocaleIdProp,
            activeTextLocaleId: activeTextLocaleIdProp,
        } = this.props;
        let inActiveCnt =
            typeof inActiveCntProp === "number"
                ? inActiveCntProp
                : allCnt - activeCnt - (futureCount || 0);
        const activeTextLocaleId = activeTextLocaleIdProp
            ? activeTextLocaleIdProp
            : DashboardLocale.ListStatesActive;
        const inActiveTextLocaleId = inActiveTextLocaleIdProp
            ? inActiveTextLocaleIdProp
            : DashboardLocale.ListStatesInactive;
        return (
            <div className={mergeClasses("list-states", this.props.className)}>
                <RadioGroup
                    value={this.props.value}
                    defaultValue={ListStates.Active}
                    onChange={this.onChange.bind(this)}
                >
                    <Radio className="all" value={ListStates.All}>
                        {this.renderState(
                            DashboardLocale.ListStatesAll,
                            this.props.allCnt - this.props.inActiveCnt
                        )}
                    </Radio>
                    <Radio className="active" value={ListStates.Active}>
                        {this.renderState(
                            activeTextLocaleId,
                            this.props.activeCnt
                        )}
                    </Radio>
                    {typeof this.props.pendingCount === "number" && (
                        <Radio className="pending" value={ListStates.Pending}>
                            {this.renderState(
                                inActiveTextLocaleId,
                                this.props.pendingCount
                            )}
                        </Radio>
                    )}
                </RadioGroup>
                {this.props.showGroupingButton && (
                    <a
                        href="javascript:"
                        style={{ marginLeft: "auto" }}
                        onClick={this.props.openGroupMode}
                    >
                        {fmtMsg({ id: DashboardLocale.LandingTabGroupSwitch })}
                    </a>
                )}
            </div>
        );
    }
}
