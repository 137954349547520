import {
    AccessLevel,
    IRouteHandler,
    OidcProxy,
    PathConfig,
    RouteHandlerContext,
    UserPermissions,
} from "gl-commonui";

export class MultiPermissionRouteHandlerRouteHandler implements IRouteHandler {
    constructor(private permissionIds: number[]) {}
    next: IRouteHandler;
    invoke(context: RouteHandlerContext) {
        MultiPermissionRouteGuard(this.next, context, this.permissionIds);
    }
}

async function MultiPermissionRouteGuard(
    next,
    context,
    permissionIds: number[]
) {
    const loginInfo = OidcProxy.getLoginInfo();
    if (loginInfo.loggedin && loginInfo.profile.localizerActions) {
        const permissions: UserPermissions[] =
            loginInfo.profile.localizerActions;
        const requiredPermissions = permissions.filter((permission) =>
            permissionIds.includes(permission.id)
        );
        if (
            requiredPermissions.length > 0 &&
            requiredPermissions.some(
                (r) => r.permissionAccessLevelId === AccessLevel.Full
            )
        ) {
            next.invoke(context);
        } else {
            context.history(
                { pathname: PathConfig.AccessDenied },
                { replace: true }
            );
        }
    } else {
        next.invoke(context);
    }
}
