import React from 'react';
import { Link } from "react-router-dom";
import { Menu } from "antd-min";
import { UserAvatarProps, GLGlobal, OidcProxy, HeaderMenuArrowNavigatorIcon, GLLocale, connect } from 'gl-commonui';
import { PathConfig } from '../config/pathconfig';
import { FormattedMessage } from 'react-intl';
import { StateType } from '@app/state';
import { DashboardLocale, LocalizerLocale } from '@app/locales/localeid';
import { showHideDrawer } from "@app/actions/show-hide-drawer";
import { PermissionAction } from '@app/util';

function redirectToPage(value) {
    if (value == "signout") {
        OidcProxy.signoutRedirect();
        return;
    }
}

function handleCloseDrawer() {
    showHideDrawer(false);
}

function redirectProfile() {
    window.open(`${GLGlobal.authorityUrl()}/Account/Profile`);
}

export function warppedUserAvatar(UserAvatar) {
    return connect(
        ({ oidc: { loginInfo } }: StateType) => ({
            actionLoaded:
                loginInfo &&
                loginInfo.loggedin &&
                loginInfo.profile &&
                loginInfo.profile.localizerActions &&
                true
        })
    )(class extends React.Component<UserAvatarProps, any> {

        constructor(props) {
            super(props);
        }

        getRequiredRoute = () => {
            let retValue = { "url": "", "isVisible": false };

            if (GLGlobal.isLocalizerActionValid(PermissionAction.View_FileAreaAndSection)) {
                retValue.isVisible=true;
                retValue.url = PathConfig.ManageAreaSectionFile;
            }
            else if (GLGlobal.isLocalizerActionValid(PermissionAction.View_TextAreaAndSection)) {
                retValue.isVisible=true;
                retValue.url = PathConfig.ManageAreaSectionText;
            }
            else if (GLGlobal.isLocalizerActionValid(PermissionAction.View_PageAreaAndSection)) {
                retValue.isVisible=true;
                retValue.url = PathConfig.ManageAreaSectionPage;
            }

            return retValue;
        }

        renderAvatarMenu = () => {
            const retValue = this.getRequiredRoute();
            return (
                <Menu onClick={() => handleCloseDrawer()}>
                    {GLGlobal.isLocalizerActionValid(PermissionAction.ManageUserPermission) && <Menu.Item key={"permissionManager"}>
                        <Link to={PathConfig.ManagePermissions} >
                            <FormattedMessage id={DashboardLocale.HeaderMenuPermissionManager} />
                        </Link>
                    </Menu.Item>}
                    {GLGlobal.isLocalizerActionValid(PermissionAction.View_TranslationManagers) && <Menu.Item key={"translationManager"}>
                        <Link to={PathConfig.TranslationManager} >
                            <FormattedMessage id={DashboardLocale.HeaderMenuTranslationManager} />
                        </Link>
                    </Menu.Item>}
                    {retValue.isVisible && <Menu.Item key={"manageArea"}>
                            <Link
                                to={retValue.url} >
                                <FormattedMessage id={DashboardLocale.HeaderMenuManageAreaSection} />
                            </Link>
                        </Menu.Item>}
                    <Menu.Item
                        className={`avatar-edit-profile navigator`}
                        key={"editProfile"}
                    >
                        <span onClick={() => redirectProfile()}>
                            <FormattedMessage id={LocalizerLocale.editProfile} />
                        </span>
                        <HeaderMenuArrowNavigatorIcon />
                    </Menu.Item>
                    <Menu.Item>
                        <div
                            onClick={() => {
                                redirectToPage("signout");
                            }}
                        >
                            <FormattedMessage id={LocalizerLocale.signOut} />
                        </div>
                    </Menu.Item>
                </Menu>
            );
        }

        render() {
            return <UserAvatar {...this.props} menuRender={() => this.renderAvatarMenu} />
        }
    });
}