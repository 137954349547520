import {Menu} from "wijmo/wijmo.input";
import {Clipboard} from "wijmo/wijmo";
import "./context-menu.less";

export class FlexGridContextMenu {
    constructor(grid) {
        let host = grid.hostElement, menu = this._buildMenu(grid);
        host.addEventListener('contextmenu', (e: Event) => {
            let ht = grid.hitTest(e);
            // do not show context menu if clicked on link
            if (ht.target && (ht.target.matches('a') || ht.target.closest('a'))) {
                return;
            }
            if (grid.selection.col > -1) {
                e.preventDefault(); // cancel the browser's default menu
                menu.show(e); // and show ours
            }
        }, true);
    }
    _buildMenu(grid) {
        return new Menu(document.createElement('div'), {
            owner: grid.hostElement,
            displayMemberPath: 'header',
            subItemsPath: 'items',
            commandParameterPath: 'cmd',
            dropDownCssClass: 'ctx-menu',
            openOnHover: true,
            closeOnLeave: true,
            itemsSource: [
                {header: 'Copy', cmd: 'CPY'},
            ],
            command: {
                // enable/disable menu commands
                canExecuteCommand: () => {
                    return true;
                },
                // execute menu commands
                executeCommand: (cmd) => {
                    switch (cmd) {
                        case 'CPY':
                            this.copyToClipboard(grid);
                            break;
                    }
                    // restore focus to active grid cell (TFS 439964)
                    grid.refresh();
                    let sel = grid.selection, cell = grid.cells.getCellElement(sel.row, sel.col);
                    if (cell) {
                        cell.focus();
                    }
                }
            }
        });
    }
    copyToClipboard(grid) {
        try {
            const text = grid.getClipString();
            Clipboard.copy(text);
        } catch (e) {
        }
    }
}
