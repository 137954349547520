import {
    GLServiceBase,
    HttpMethod,
    ServerExceptionCode,
    getServiceMethodKey,
    ServiceRoute,
} from "gl-commonui";
import { injectable } from "@app/util/di";
import { IApplicationService } from "./interface";
import {
    GetApplicationRequest,
    ApplicationRequest,
    ApplicationModel,
    ApplicationInfoModel,
} from "./model";

@injectable()
export class ApplicationService
    extends GLServiceBase<ApplicationRequest, GetApplicationRequest>
    implements IApplicationService
{
    serviceRoute = { prefix: "localizer/v1/application", itemSuffix: "{id}" };

    getApplicationsText() {
        return this.get("text");
    }

    getApplicationsFile(): Promise<ApplicationModel[]> {
        return this.get("file");
    }

    getApplicationsPage() {
        return this.get("pages");
    }

    getApplicationsByType(typeId?: number): Promise<ApplicationInfoModel[]> {
        return this.get({ typeId });
    }

    getSectionsByAppId(applicationId?: number) {
        return this.get("sections", { applicationId });
    }
}
