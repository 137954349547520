import { GLResponse, GLRequest, GLPagingRequest } from "gl-commonui";

export interface RedeemModel {
    email?: string;
    password?: string;
}

export enum RedeemProps {
    email = "email",
    password = "password"
}

export interface AccountModel {
    id?: string;
    name?: string;
}

export interface UpdateEmailRequestModel {
    userId: string;
    email: string;
}

export class GetAccountRequest extends GLPagingRequest {
    ids?: string[];
}
export interface AccountRequest extends GLRequest, AccountModel {}
export interface AccountResponse extends GLResponse, AccountModel {}
