import { GLServiceBase } from 'gl-commonui'
import { injectable } from '../../util/di'
import { IAccountService, GetAccountRequest, AccountRequest } from './index';

@injectable()
export class AccountService extends GLServiceBase<AccountRequest, GetAccountRequest> implements IAccountService {
    serviceRoute = { prefix: 'account/v1', itemSuffix: '{id}' }
    validateEmail(params: any) {
        return this.get('users/{email}/confirmation', params);
    }
    deleteUser(user: string) {
        return this.request.post(this.url(`~account/v1/users/${user}/removetranslationmanager`), { id: user});
    }
    getTranslationManagersList( ){
        return this.get('users', { role: "TranslationManager", disabled:'false',onlyTranslationManager:'true',includePendingInvite:'true'});
    }
    bindingUserByEmail(params: any) {
        return this.request.post(this.url(`~account/v1/users/${params.email}/binding`), params);
    }
}
