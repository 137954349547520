import { GLRequest, GLPagingRequest } from 'gl-commonui';

export interface AdminModel {
    id?: string;
    name?: string;
}

export interface AdminRequest extends GLRequest, AdminModel {
}

export class GetAdminRequest extends GLPagingRequest {
}