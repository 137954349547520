export class LocalizerLocale {
    static admin = "common.admin";
    static signIn = "common.signIn";
    static signOut = "common.signOut";
    static editProfile = "common.editProfile";
    static projectName = "common.projectName";
    static DashboardMenuAreaTitle = "dashboard.areaTitle";
    static DashboardMenuSectionTitle = "dashboard.sectionTitle";
    static DashboardMenuNoArea = "dashboard.noArea";
    static DashboardMenuNoSection = "dashboard.noSection";
    static DashboardAddOption = "dashboard.addOption";
    static DashboardTableTitle = "dashboard.tableTitle";
    static DashboardTableTag = "dashboard.tableTag";
    static DashboardTablePermission = "dashboard.tablePermission";
    static DashboardDocumentCreate = "dashboard.create";
    static DashboardDocumentUpdate = "dashboard.update";
    static DashboardSite = "dashboard.site";
    static DashboardStatus = "dashboard.status";
    static DashboardSection = "dashboard.section";
    static DashboardTitle = "dashboard.title";
    static DashboardTags = "dashboard.tags";
    static DashboardPermission = "dashboard.permission";
    static DashboardDelete = "dashboard.delete";
    static DashboardSave = "dashboard.save";
    static DashboardCancel = "dashboard.cancel";
    static CommonEntityNotExist = "common.entityNotExist";
    static EditAreaTitle = "editArea.title";
    static CreateAreaTitle = "createArea.title";
    static CreateAreaExist = "createArea.exist";
    static CreateAreaSectionExist = "createAreaSection.exist";
    static SectionIsEmpty = "createAreaSection.sectionEmpty";
    static SectionSaved = "createAreaSection.sectionSaved";
    static SectionNameIsEmpty = "createAreaSection.sectionNameIsEmpty";
    static EditAreaImportTitle = "editArea.importTitle";
    static EditAreaImportDesc = "editArea.importDesc";
    static EditAreaName = "editArea.name";
    static CreateAreaName = "createArea.name";
    static EditAreaDelete = "editArea.delete";
    static EditAreaDeleteMsg = "editArea.deleteMsg";
    static EditAreaDeleteTitle = "editArea.deleteTitle";
    static EditAreaSave = "editArea.save";
    static EditAreaSaveConfirmation = "editArea.saveConfirmations";
    static EditAreaCancel = "editArea.cancel";
    static EditAreaUploadJson = "editArea.uploadJson";
    static EditAreaSectionTitle = "editArea.sectionTitle";
    static EditAreaAddSection = "editArea.sectionAdd";
    static ManageAreaSectionTextLink = "manageAreaSection.text.link";
    static ManageAreaSectionFileLink = "manageAreaSection.file.link";
    static ManageAreaSectionPageLink = "manageAreaSection.page.link";
    static ManageAreaSectionFileListName = "manageAreaSection.filelist.name";
    static ManageAreaSectionFileListUngroupedKeys =
        "manageAreaSection.filelist.ungroupedkeys";
    static ManageAreaSectionFileListAllowPublish =
        "manageAreaSection.filelist.allowpublish";
    static ManageAreaSectionTextListName = "manageAreaSection.filelist.name";
    static ManageAreaSectionTextListUngroupedKeys =
        "manageAreaSection.textlist.ungroupedkeys";
    static ManageAreaSectionTextListAllowPublish =
        "manageAreaSection.textlist.allowpublish";
    static HomeWelcome = "home.index.welcome";
    static LocalizerHomePage = "home.index.localizer";
    static PermissionManagerHeader = "permissionManager.header";
    static PermissionManagerSuccessMsg = "permissionManager.successMsg";
    static PermissionManagerSelectUser = "permissionManager.selectUser";
    static PermissionManagerNoUser = "permissionManager.noUser";
    static PermissionManagerNone = "permissionManager.none";
    static PermissionManagerFull = "permissionManager.full";
    static PermissionManagerSaveButton = "permissionManager.saveButton";
    static PermissionManagerFileManagment =
        "permissionManager.file.fileManagment";
    static PermissionManagerTextManagment =
        "permissionManager.text.textManagment";
    static PermissionManagerPageManagment =
        "permissionManager.page.pageManagment";
    static PermissionManagerAdminManagment =
        "permissionManager.admin.adminManagment";
    static PermissionManagerFileView = "permissionManager.file.view";
    static PermissionManagerFileAddEdit = "permissionManager.file.addEdit";
    static PermissionManagerFileTranslateDeveloperView =
        "permissionManager.file.translateDeveloper";
    static PermissionManagerFileTranslateTranslatorView =
        "permissionManager.file.translateTranslator";
    static PermissionManagerFileManageFileGroups =
        "permissionManager.file.manageFileGroups";
    static PermissionManagerTextView = "permissionManager.text.view";
    static PermissionManagerTextAddEdit = "permissionManager.text.addEdit";
    static PermissionManagerTextTranslateDeveloperView =
        "permissionManager.text.translateDeveloper";
    static PermissionManagerTextTranslateTranslatorView =
        "permissionManager.text.translateTranslator";
    static PermissionManagerTextMangeFileGroups =
        "permissionManager.text.manageFileGroups";
    static PermissionManagerTextPublishToTest =
        "permissionManager.text.publishToTest";
    static PermissionManagerTextPublisToLive =
        "permissionManager.text.publishToLive";
    static PermissionManagerPageView = "permissionManager.page.view";
    static PermissionManagerPageAddEdit = "permissionManager.page.addEdit";
    static PermissionManagerPageTranslateDeveloperView =
        "permissionManager.page.translateDeveloper";
    static PermissionManagerPageTranslateTranslatorView =
        "permissionManager.page.translateTranslator";
    static PermissionManagerPagePublishToTest =
        "permissionManager.page.publishToTest";
    static PermissionManagerPagePublisToLive =
        "permissionManager.page.publishToLive";
    static PermissionManagerAdminView = "permissionManager.admin.view";
    static PermissionManagerAdminAddEdit = "permissionManager.admin.addEdit";
    static PermissionManagerAdminManageUserPermissions =
        "permissionManager.admin.manageUserPermissions";
    static LocalizerAccessDeniedMessage = "localizer.accessdenied.message";
    static TranslationManagerHeader = "translationManager.header";
    static AddAreaSections = "manageAreaSections.add";
    static ManageAreaSection = "manageAreaSections.header";
    static ManagePagesTitle = "managePages.title";
    static ManageGroups = "manageAreaSections.manageGroups";
    static ManagePages = "manageAreaSections.managePages";
    static TranslationManagerInviteUser = "translationManager.inviteUser";
    static TranslationManagerSuccessMsg = "translationManager.successMsg";
    static TranslationManagerInvite = "translationManager.invite";
    static TranslationManagerInviteSend = "translationManager.inviteSend";
    static TranslationManagerInviteCancel = "translationManager.inviteCancel";
    static TranslationManagerInviteEmail = "translationManager.inviteEmail";
    static TranslationManagerEnterEmail = "translationManager.enterEmail";
    static TranslationManagerEnterName = "translationManager.enterName";
    static TranslationManagerDeleteUser = "translationManager.deleteUser";
    static TranslationManagerTemplate = "translationManager.template";
    static TranslationManagerValidateEmail = "translationManager.validateEmail";
    static TranslationManagerEmailExist = "translationManager.emailExist";
    static TranslationManagerEmailExistInstruction =
        "translationManager.emailExistInstruction";
    static ManagePageListName = "managePage.list.name";
    static ManagePageListUrl = "managePage.list.url";
    static ManagePageListTags = "managePage.list.tags";
    static ManagePageAreaLabel = "managePage.areaLabel";
    static ManagePageFormAddTitle = "managePage.form.addTitle";
    static ManagePageFormEditTitle = "managePage.form.editTitle";
    static ManagePageFormNameLabel = "managePage.form.nameLabel";
    static ManagePageFormUrlLabel = "managePage.form.urlLabel";
    static ManagePageFormTagLabel = "managePage.form.tagLabel";
    static ManagePageFormNameRequiredMessage =
        "managePage.form.nameRequiredMessage";
    static ManagePageFormNameLengthMessage =
        "managePage.form.nameLengthMessage";
    static ManagePageFormNameNotUniqueMessge =
        "managePage.form.nameNotUniqueMessage";
    static ManagePageFormUrlRequiredMessage =
        "managePage.form.urlRequiredMessage";
    static ManagePageFormUrlInvalidMessage =
        "managePage.form.urlInvalidMessage";
    static ManagePageFormUrlNotUniqueMessge =
        "managePage.form.urlNotUniqueMessage";
    static ManagePageFormTagRequiredMessge =
        "managePage.form.tagRequiredMessage";
    static ManagePageFormTagLengthMessge = "managePage.form.tagLengthMessage";
    static ManagePageFormTagMessge = "managePage.form.tagMessage";
    static ManagePageFormDeleteMessage = "managePage.form.deleteMessage";
    static ManagePageFormDeleteButton = "managePage.form.deleteButton";
    static ManagePageFormSaveButton = "managePage.form.saveButton";
    static ManagePageFormCancelButton = "managePage.form.cancelButton";
    static ManagePageFormDeleteModalTitle = "managePage.form.deleteModalTitle";
}

export class DashboardLocale {
    static ListStatesActive = "dashboard.common.liststatesactive";
    static ListStatesAll = "dashboard.common.liststatesall";
    static ListStatesInactive = "dashboard.common.liststatesinactive";
    static LandingTabGroupSwitch = "landingtab.groupswitch.text";
    static HeaderMenuTranslationManager =
        "dashboard.headermenu.translationManager";
    static HeaderMenuAdministration = "dashboard.headermenu.administration";
    static HeaderMenuManageAreaSection =
        "dashboard.headermenu.manageareasection";
    static HeaderMenuPermissionManager =
        "dashboard.headermenu.permissionManager";
}

export class SchoolLocale {
    static TeacherPending = "class_teacher.item.pending";
    static ListStatesFuture = "school.common.liststatesfuture";
}
