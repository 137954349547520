import { useEffect, useState } from "react";
import { FlexGrid } from "wijmo/wijmo.grid";

export function useWijmoGridValidCheck(grid: FlexGrid) {
    const [isValid, setIsValid] = useState<boolean>(false);

    useEffect(() => {
        if (grid) {
            grid.cellEditEnded.addHandler(findAndSetError);
            grid.loadedRows.addHandler(findAndSetError);
            grid.pasted.addHandler(findAndSetError);
            grid.deletedRow.addHandler(findAndSetError);
            findAndSetError();
        }

        return () => {
            if (grid) {
                grid.cellEditEnded.removeHandler(findAndSetError);
                grid.loadedRows.removeHandler(findAndSetError);
                grid.pasted.removeHandler(findAndSetError);
                grid.deletedRow.removeHandler(findAndSetError);
            }
        };
    }, [grid]);

    const findAndSetError = () => {
        const isValid = grid.hostElement && !grid.hostElement.querySelectorAll(".wj-state-invalid").length;
        setIsValid(isValid);

        setTimeout(() => {
            const isValid = grid.hostElement && !grid.hostElement.querySelectorAll(".wj-state-invalid").length;
            setIsValid(isValid);
        }, 200);
    };

    const manualTrigger = findAndSetError;

    return { isValid, manualTrigger };
}
