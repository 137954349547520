import { GLRequest, GLPagingRequest } from "gl-commonui";

export interface ApplicationModel {
    id: number;
    name: string;
}

export interface ApplicationInfoModel {
    id: number;
    name: string;
    transalationTypeId: number;
}

export interface SectionModel {
    id?: any;
    name?: string;
    applicationId?: number;
    isVisible?: boolean;
    displayName?: string;
}

export interface ApplicationRequest extends GLRequest, ApplicationModel {}

export class GetApplicationRequest extends GLPagingRequest {}
