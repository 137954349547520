import { useRef, useEffect, EffectCallback, DependencyList } from "react";

export function useEffectAfterFirst(effect: EffectCallback, deps?: DependencyList): void {
    const ref = useRef(true);

    useEffect(() => {
        if (ref.current) {
            ref.current = false;
        } else {
            return effect();
        }
    }, deps);
}
