import { FlexGrid, HeadersVisibility } from "wijmo/wijmo.grid";
import * as ResizeSensor from "resize-sensor";
import { debounce } from "lodash";

/**
 * The function make the grid responsive with adding horizontal scrollbar on the small screen sizes.
 * The function should be called in initilize function of the grid with the grid obejct.
 * @param grid FlexGrid object
 */
export const wijmoGridResponsive = (grid: FlexGrid) => {
    /**
     * Saving the frozenColumns in the variable.
     * To make the frozenColumns as 0 on the small screen.
     */
    const frozenColumns = grid.frozenColumns;

    // Registering the rezise listener to grid.
    grid.addEventListener(window, "resize", () => {
        if (!grid.editRange) {
            wijmoGirdResponsiveHandler(grid, frozenColumns);
        }
    });

    // Calling the wijmoGirdResponsiveHandler while rendering the rows
    grid.loadedRows.addHandler((grid) => wijmoGirdResponsiveHandler(grid, frozenColumns, 500));

    // Calling the wijmoGirdResponsiveHandler while grid layout gets updated once
    let isResizeDone = false;
    grid.updatedLayout.addHandler((grid) => {
        if (!isResizeDone) {
            wijmoGirdResponsiveHandler(grid, frozenColumns, 500);
            isResizeDone = true;
        }
    });

    // Calling the wijmoGirdResponsiveHandler when a cell has been edited
    grid.cellEditEnded.addHandler((grid, event) => {
        const column = grid.columns[event.col];
        if (column && column.minWidth && column.maxWidth) {
            return null;
        }
        wijmoGirdResponsiveHandler(grid, frozenColumns, 200);
    });

    // Registering the rezise listener to grid.
    let currentWidth = getGridFullWidth(grid);
    const debouncedResponsiveHandler = debounce(() => {
        wijmoGirdResponsiveHandler(grid, frozenColumns);
    }, 300);
    new ResizeSensor(grid.hostElement, () => {
        const newWidth = getGridFullWidth(grid);
        if (currentWidth !== newWidth) {
            debouncedResponsiveHandler();
        }
        currentWidth = newWidth;
    });
};

export const wijmoGirdResponsiveHandler = (grid: FlexGrid, frozenColumns: number, timeout = 200) => {
    if (!grid.collectionView) {
        return null;
    }
    setTimeout(() => {
        grid.autoSizeColumns();
        if (grid.autoRowHeights) {
            grid.autoSizeRows();
        }
        const gridFullWidth = getGridFullWidth(grid);
        const columnsWidth = grid.columns.getTotalSize();
        if (gridFullWidth > columnsWidth) {
            const columns = grid.columns.filter((column) => column.visible && !column.maxWidth);
            const columnCount = columns.length;
            const extraWidthForEachColumn = (gridFullWidth - columnsWidth) / columnCount;
            columns.forEach((column) => {
                const widthType = column.minWidth ? "minWidth" : "width";
                column[widthType] = column[widthType] + extraWidthForEachColumn;
            });
        }

        // Setting the frozenColumns as 0 on small screen
        if (gridFullWidth <= 768) {
            grid.frozenColumns = 0;
        } else {
            grid.frozenColumns = frozenColumns;
        }
    }, timeout);
};

// A utility function to get the width of a grid.
const getGridFullWidth = (grid: FlexGrid): number => {
    if (grid.headersVisibility === HeadersVisibility.All || grid.headersVisibility === HeadersVisibility.Row) {
        return grid.hostElement.offsetWidth - grid.rowHeaders.width;
    }
    return grid.hostElement.offsetWidth;
};
