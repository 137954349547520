import {
    GLServiceBase,
    HttpMethod,
    ServerExceptionCode,
    getServiceMethodKey,
    ServiceRoute,
} from "gl-commonui";
import { injectable } from "@app/util/di";
import { ITagService } from "./interface";
import { TagModel } from "./model";

@injectable()
export class TagService extends GLServiceBase<any, any> implements ITagService {
    serviceRoute = { prefix: "localizer/v1/tag", itemSuffix: "{id}" };

    getTags(): Promise<TagModel[]> {
        return this.get();
    }
}
