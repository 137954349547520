import { LocalizerLocale } from "@app/locales/localeid";

export const GiveAccess = {
    Full: LocalizerLocale.PermissionManagerFull,
    None: LocalizerLocale.PermissionManagerNone
}


export const permissionParentNamesMap = {
    "Manage_FileAreaAndSection": LocalizerLocale.PermissionManagerFileManagment,
    "Manage_TextAreaAndSection": LocalizerLocale.PermissionManagerTextManagment,
    "Manage_PageAreaAndSection": LocalizerLocale.PermissionManagerPageManagment,
    "Admin": LocalizerLocale.PermissionManagerAdminManagment
};

export const localizedParentStringsMap = {
    "Manage_FileAreaAndSection": LocalizerLocale.PermissionManagerFileManagment,
    "Manage_TextAreaAndSection": LocalizerLocale.PermissionManagerTextManagment,
    "Manage_PageAreaAndSection": LocalizerLocale.PermissionManagerPageManagment,
    "Admin": LocalizerLocale.PermissionManagerAdminManagment
};

export const permissionChildNamesMap = {
    "View_FileAreaAndSection": LocalizerLocale.PermissionManagerFileView,
    "AddEdit_FileAreaAndSection": LocalizerLocale.PermissionManagerFileAddEdit,
    "Translate_FileAreaAndSection_DeveloperView": LocalizerLocale.PermissionManagerFileTranslateDeveloperView,
    "Translate_FileAreaAndSection_TranslatorView": LocalizerLocale.PermissionManagerFileTranslateTranslatorView,
    "ManageGroups_FileAreaAndSection": LocalizerLocale.PermissionManagerFileManageFileGroups,
    "View_TextAreaAndSection": LocalizerLocale.PermissionManagerTextView,
    "AddEdit_TextAreaAndSection": LocalizerLocale.PermissionManagerTextAddEdit,
    "Translate_TextAreaAndSection_DeveloperView": LocalizerLocale.PermissionManagerTextTranslateDeveloperView,
    "Translate_TextAreaAndSection_TranslatorView": LocalizerLocale.PermissionManagerTextTranslateTranslatorView,
    "ManageGroups_TextAreaAndSection": LocalizerLocale.PermissionManagerTextMangeFileGroups,
    "PublishToTest_TextAreaAndSection": LocalizerLocale.PermissionManagerTextPublishToTest,
    "PublishToLive_TextAreaAndSection": LocalizerLocale.PermissionManagerTextPublisToLive,
    "View_PageAreaAndSection": LocalizerLocale.PermissionManagerPageView,
    "AddEdit_PageAreaAndSection": LocalizerLocale.PermissionManagerPageAddEdit,
    "Translate_PageAreaAndSection_DeveloperView": LocalizerLocale.PermissionManagerPageTranslateDeveloperView,
    "Translate_PageAreaAndSection_TranslatorView": LocalizerLocale.PermissionManagerPageTranslateTranslatorView,
    "PublishToTest_PageAreaAndSection": LocalizerLocale.PermissionManagerPagePublishToTest,
    "PublishToLive_PageAreaAndSection": LocalizerLocale.PermissionManagerPagePublisToLive,
    "View_TranslationManagers": LocalizerLocale.PermissionManagerAdminView,
    "AddEdit_TranslationManagers": LocalizerLocale.PermissionManagerAdminAddEdit,
    "ManageUserPermission": LocalizerLocale.PermissionManagerAdminManageUserPermissions,
};

export const localizedChildStringsMap = {
    "View_FileAreaAndSection": LocalizerLocale.PermissionManagerFileView,
    "AddEdit_FileAreaAndSection": LocalizerLocale.PermissionManagerFileAddEdit,
    "Translate_FileAreaAndSection_DeveloperView": LocalizerLocale.PermissionManagerFileTranslateDeveloperView,
    "Translate_FileAreaAndSection_TranslatorView": LocalizerLocale.PermissionManagerFileTranslateTranslatorView,
    "ManageGroups_FileAreaAndSection": LocalizerLocale.PermissionManagerFileManageFileGroups,
    "View_TextAreaAndSection": LocalizerLocale.PermissionManagerTextView,
    "AddEdit_TextAreaAndSection": LocalizerLocale.PermissionManagerTextAddEdit,
    "Translate_TextAreaAndSection_DeveloperView": LocalizerLocale.PermissionManagerTextTranslateDeveloperView,
    "Translate_TextAreaAndSection_TranslatorView": LocalizerLocale.PermissionManagerTextTranslateTranslatorView,
    "ManageGroups_TextAreaAndSection": LocalizerLocale.PermissionManagerTextMangeFileGroups,
    "PublishToTest_TextAreaAndSection": LocalizerLocale.PermissionManagerTextPublishToTest,
    "PublishToLive_TextAreaAndSection": LocalizerLocale.PermissionManagerTextPublisToLive,
    "View_PageAreaAndSection": LocalizerLocale.PermissionManagerPageView,
    "AddEdit_PageAreaAndSection": LocalizerLocale.PermissionManagerPageAddEdit,
    "Translate_PageAreaAndSection_DeveloperView": LocalizerLocale.PermissionManagerPageTranslateDeveloperView,
    "Translate_PageAreaAndSection_TranslatorView": LocalizerLocale.PermissionManagerPageTranslateTranslatorView,
    "PublishToTest_PageAreaAndSection": LocalizerLocale.PermissionManagerPagePublishToTest,
    "PublishToLive_PageAreaAndSection": LocalizerLocale.PermissionManagerPagePublisToLive,    
    "View_TranslationManagers": LocalizerLocale.PermissionManagerAdminView,
    "AddEdit_TranslationManagers": LocalizerLocale.PermissionManagerAdminAddEdit,
    "ManageUserPermission": LocalizerLocale.PermissionManagerAdminManageUserPermissions,
};