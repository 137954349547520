import * as React from "react";
import { CellRange, CellRangeEventArgs, Column as IFlexGridColumn } from "wijmo/wijmo.grid";
import { FlexGridCellTemplate, FlexGridColumn, ICellTemplateContext } from "wijmo/wijmo.react.grid";
import { GridCellTemplateType } from "wijmo/wijmo.interop.grid";
import { ReactNode } from "react";
import { WijmoCellEditCallback, WijmoColumnValidatorFn } from "@app/hooks";
import { isFunction } from "lodash";

interface ColumnProps extends Partial<IFlexGridColumn> {
    binding?: string;
    render?: (value: any, item: any, context: GridICellTemplateContext) => React.ReactNode;
    renderHeader?: () => React.ReactNode;
    renderEditor?: (context: ICellTemplateContext, value: any, item: any) => React.ReactNode;
    validators?: WijmoColumnValidatorFn[]; // this is been utilized through grid component.

    /** Whether to get a disable check in render function */
    cellDisableHandler?: WijmoCellEditCallback;
}

export const Column: React.FC<ColumnProps> = (props) => {
    const { render, binding, renderHeader, renderEditor, validators, cellDisableHandler, ...rest } = props;
    const renderCell = (context: GridICellTemplateContext) => {
        const grid = context.row.grid;
        if (grid && cellDisableHandler && isFunction(cellDisableHandler)) {
            const event = new CellRangeEventArgs(context.col.grid.cells, new CellRange(context.row.index, context.col.index));
            cellDisableHandler(context.row.grid, event, context.item);
            context.isDisabled = event.cancel;
        }
        return render(context.item[binding], context.item, context);
    };
    const renderCellEditor = (context: ICellTemplateContext) => renderEditor(context, context.item[binding], context.item);
    const renderColumnHeader = (context: ICellTemplateContext) => {
        const currentSort = context.col.currentSort;
        return (
            <>
                {renderHeader()}
                {renderSortIcon(currentSort)}
            </>
        );
    };

    const renderSortIcon = (sort: string): ReactNode => {
        if (sort === "+") {
            return <span className="wj-glyph-up"></span>;
        } else if (sort === "-") {
            return <span className="wj-glyph-down"></span>;
        }
        return null;
    };

    return (
        <FlexGridColumn binding={binding} {...rest}>
            {renderHeader && <FlexGridCellTemplate cellType={GridCellTemplateType.ColumnHeader} template={renderColumnHeader} />}
            {render && <FlexGridCellTemplate cellType={GridCellTemplateType.Cell} template={renderCell} />}
            {renderEditor && <FlexGridCellTemplate cellType={GridCellTemplateType.CellEdit} template={renderCellEditor} />}
        </FlexGridColumn>
    );
};

Column.defaultProps = {
    isReadOnly: true,
    header: " ", // To set the header as blank by default
    binding: null,
};

export interface GridICellTemplateContext extends ICellTemplateContext {
    isDisabled?: boolean;
}
