import {GLServiceBase, HttpMethod, ServerExceptionCode, getServiceMethodKey} from 'gl-commonui'
import {injectable} from '@app/util/di'
import {ILocalizerService} from './interface';
import {GetAdminRequest, AdminRequest} from './model';

@injectable()
export class LocalizerService extends GLServiceBase<AdminRequest, GetAdminRequest> implements ILocalizerService {
    serviceRoute = {prefix: 'admin/v1/invitationTemplates', suffix: '{id}'}

    getTemplate() {
        return this.get('candidates', {isDescending: 'true', detail: 'true', invitationType: '15'});
    }

    inviteUser(params: any) {
        return this.request.post(this.url('~admin/v1/invitationcodes'), params);
    }

    deletePendingUser(userId: string) {
        return this.delete(`~admin/v1/invitationcodes/${userId}`);
    }
}
