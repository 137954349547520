const TYPES = {
    ILocalizerService: Symbol("ILocalizerService"),
    ILocalizerSections: Symbol("ILocalizerSections"),
    IAccountService: Symbol("IAccountService"),
    IApplicationService: Symbol("IApplicationService"),
    IDocumentsService: Symbol("IDocumentsService"),
    IPageService: Symbol("IPageService"),
    ITagService: Symbol("ITagService"),
    IPermissionService: Symbol("IPermissionService"),
};

export { TYPES };
