import { TagModel, TagService } from "@app/service/tags";

export interface TagState {
    tags: TagModel[];
    loading: boolean;
}
interface Services {
    tagService: TagService;
}

export default {
    namespace: "tag",
    state: {
        tags: [],
        loading: false,
    },
    reducers: {
        reload(state, { payload }) {
            return { ...state, ...payload };
        },
    },
    effects: {
        *getTags({ payload }, { call, put }, { tagService }: Services) {
            yield put(reload({ loading: true }));
            const data = yield call(tagService.getTags, payload);
            yield put(reload({ tags: data, loading: false }));
        },
    },
    services: {
        tagService: TagService,
    },
};

export function reload(state) {
    return { type: "tag/reload", payload: state };
}

export function getTags(state) {
    return { type: "tag/getTags", payload: state };
}
