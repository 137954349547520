import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Menu, MenuProps, Tabs, TabsProps } from "antd";
import { FormattedMessage } from "react-intl";
import { LocalizerLocale } from "@app/locales/localeid";
import { ManageAreaPageType, PermissionAction } from "@app/util";
import {
    FileOutlined,
    FontSizeOutlined,
    GlobalOutlined,
} from "@ant-design/icons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PathConfig } from "@app/config/pathconfig";
import { Container } from "@app/components/container/container";
import "./manage-area-section.less";
import { GLGlobal } from "gl-commonui";

export const ManageAreaSection: React.FC<any> = () => {
    const [current, setCurrent] = useState(ManageAreaPageType.File);
    const fileSectionPaths = [
        PathConfig.ManageAreaSectionFile,
        PathConfig.ManageAreaSectionFileAddEdit,
    ];
    const pageSectionPaths = [
        PathConfig.ManageAreaSectionPage,
        PathConfig.ManageAreaSectionManagePage,
    ];
    const textSectionPaths = [PathConfig.ManageAreaSectionText];
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const pathname = location.pathname;
        const fileId = pathname.match(/\/file\/addedit\/(\d+)/)?.[1];
        const pageId = pathname.match(/\/page\/addedit\/(\d+)/)?.[1];
        const textId = pathname.match(/\/text\/addedit\/(\d+)/)?.[1];

        if (textSectionPaths.includes(pathname) || textId) {
            setCurrent(ManageAreaPageType.Text);
        } else if (pageSectionPaths.includes(pathname) || pageId) {
            setCurrent(ManageAreaPageType.Page);
        } else if (fileSectionPaths.includes(pathname) || fileId) {
            setCurrent(ManageAreaPageType.File);
        }

        if (textSectionPaths.includes(pathname)) {
            navigate(
                GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_TextAreaAndSection
                )
                    ? PathConfig.ManageAreaSectionText
                    : PathConfig.LocalizerAccessDenied
            );
        } else if (pageSectionPaths.includes(pathname)) {
            navigate(
                GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_PageAreaAndSection
                )
                    ? PathConfig.ManageAreaSectionPage
                    : PathConfig.LocalizerAccessDenied
            );
        } else if (fileSectionPaths.includes(pathname)) {
            navigate(
                GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_FileAreaAndSection
                )
                    ? PathConfig.ManageAreaSectionFile
                    : PathConfig.LocalizerAccessDenied
            );
        }
    }, [location.pathname]);

    const navigateTo = (value) => {
        if (value == ManageAreaPageType.File) {
            navigate(PathConfig.ManageAreaSectionFile);
            setCurrent(ManageAreaPageType.File);
        } else if (value == ManageAreaPageType.Page) {
            navigate(PathConfig.ManageAreaSectionPage);
            setCurrent(ManageAreaPageType.Page);
        } else if (value == ManageAreaPageType.Text) {
            navigate(PathConfig.ManageAreaSectionText);
            setCurrent(ManageAreaPageType.Text);
        }
    };

    return (
        <Container fullWidth className="reg-page">
            <div className="nav-section">
                {GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_FileAreaAndSection
                ) && (
                    <Button
                        className={"nav-button-custom"}
                        icon={<FileOutlined />}
                        type={
                            current === ManageAreaPageType.File
                                ? "primary"
                                : "default"
                        }
                        onClick={() => navigateTo(ManageAreaPageType.File)}
                    >
                        <FormattedMessage
                            id={LocalizerLocale.ManageAreaSectionFileLink}
                        />
                    </Button>
                )}
                {GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_TextAreaAndSection
                ) && (
                    <Button
                        className={"nav-button-custom"}
                        icon={<FontSizeOutlined />}
                        type={
                            current === ManageAreaPageType.Text
                                ? "primary"
                                : "default"
                        }
                        onClick={() => navigateTo(ManageAreaPageType.Text)}
                    >
                        <FormattedMessage
                            id={LocalizerLocale.ManageAreaSectionTextLink}
                        />
                    </Button>
                )}
                {GLGlobal.isLocalizerActionValid(
                    PermissionAction.View_PageAreaAndSection
                ) && (
                    <Button
                        className={"nav-button-custom"}
                        icon={<GlobalOutlined />}
                        type={
                            current === ManageAreaPageType.Page
                                ? "primary"
                                : "default"
                        }
                        onClick={() => navigateTo(ManageAreaPageType.Page)}
                    >
                        <FormattedMessage
                            id={LocalizerLocale.ManageAreaSectionPageLink}
                        />
                    </Button>
                )}
            </div>
            <Outlet />
        </Container>
    );
};
